import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';

import { UrlService } from '@shared/services';
import { ResourceTypeAttribute, ResourceTypeExtraField, ResourceTypeMetadata } from '@shared/domain';

import { RobawsResourceTypeService } from '@app/robaws/services';

import { BulkAction } from './bulk-actions/bulk-actions';
import { PageRequest } from './search/data-table-search.component';
import { TextTableColumnComponent } from './column/text-column/text-table-column.component';
import { ConfiguredColumn, TableColumn } from './column/table-column';
import { DynamicDataSource } from './dynamic-data-source';

@Component({
  selector: 'data-table',
  templateUrl: 'data-table.component.html',
  styleUrls: ['data-table.component.scss'],
})
export class DataTableComponent<T> implements OnInit, AfterContentChecked {
  @ViewChild('datatable') datatable: ElementRef;

  @Input()
  public allowFilter = true;

  @Input()
  public resourceType: string;

  @Input()
  public allowMultipleSelections = false;

  @Input()
  public allowRowSelection = true;

  @Input()
  public dataSource: DynamicDataSource<T> = null as unknown as DynamicDataSource<T>;

  @Input()
  public searchPlaceholder?: string;

  @Input()
  public bulkActions: BulkAction<T>[] = [];

  @Input()
  public columns: TableColumn<T>[] = [];
  loading = false;
  selected: T[] = [];
  selectedColumns: ConfiguredColumn[] = [];
  private _columns: TableColumn<T>[] = [];
  private metadata: ResourceTypeMetadata | null;

  constructor(
    private urlService: UrlService,
    private resourceTypeService: RobawsResourceTypeService,
    private ref: ChangeDetectorRef,
  ) {}

  get calculatedColumns(): TableColumn<T>[] {
    if (this.selectedColumns.length) {
      return this.selectedColumns.reduce((result: TableColumn<T>[], config) => {
        const matchedColumns = this._columns.filter((column) => {
          return (config.parentName ? config.parentName === column.parentId : true) && config.name === column.property && column.type === config.type;
        });

        if (matchedColumns.length) {
          result = [...result, ...matchedColumns];
        }

        return result;
      }, []);
    }

    return this._columns;
  }

  public ngOnInit(): void {
    this._columns = this.columns;

    this.dataSource?.loading$.subscribe((loading) => {
      if (!loading) {
        this.datatable?.nativeElement?.querySelector('.p-datatable-wrapper')?.scroll({ top: 0 });
        this.selected = [];
      }
      this.loading = loading;
    });
  }

  public ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  public getCurrentSelections(): T[] {
    return this.selected;
  }

  protected generateColumn(item: ResourceTypeAttribute, parent?: ResourceTypeAttribute): TableColumn<T> {
    return new TableColumn<T>(
      item.name,
      item.displayName,
      TextTableColumnComponent,
      (it: any) => {
        if (parent) {
          return {
            content: (it[parent.name] && it[parent.name][item.name]) || '',
          };
        }
        return {
          content: it[item.name] || '',
        };
      },
      item.sortable || false,
      'attributes',
      parent?.name || '',
      parent?.displayName || '',
    );
  }

  protected buildExtraField(item: ResourceTypeExtraField): TableColumn<T> {
    return new TableColumn<T>(
      item.name,
      item.displayName,
      TextTableColumnComponent,
      (it: any) => {
        const value = (it.extraFields && it.extraFields[item.displayName]) || '';
        return {
          content: typeof value === 'string' ? value : '',
        };
      },
      item.sortable || false,
      'extraFields',
    );
  }

  protected initColumn(): void {
    const columns: TableColumn<T>[] = (this.metadata?.attributes || []).reduce((columns: TableColumn<T>[], item) => {
      if (item) {
        if (item.dataType === 'COMPLEX' && item.attributes && item.attributes.length > 0) {
          item.attributes?.forEach((attribute) => {
            columns.push(this.generateColumn(attribute, item));
          });
        } else {
          columns.push(this.generateColumn(item));
        }
      }

      return columns;
    }, []);

    (this.metadata?.extraFields || []).forEach((item) => {
      columns.push(this.buildExtraField(item));
    });

    this._columns = columns;
  }

  protected changeColumn(columns: ConfiguredColumn[]): void {
    this.selectedColumns = columns;
  }

  protected onRowDblClick(_: MouseEvent, item: T): void {
    this.urlService.navigateToResourceType(this.resourceType, (item as any).id);
  }

  protected onPageRequest(pageRequest: PageRequest): void {
    this.dataSource.load(pageRequest.query, pageRequest.page, pageRequest.limit);
  }

  protected fetch(event: TableLazyLoadEvent): void {
    const limit = event.rows || 20;
    const first = event.first || 0;
    const pageIndex = first / limit;

    let sort = '';
    if (event.multiSortMeta) {
      for (const sortMeta of event.multiSortMeta) {
        sort = sort.concat(`${sortMeta.field}:${sortMeta.order === 1 ? 'asc' : 'desc'}`);
      }
    }

    if (sort !== '') {
      this.dataSource.load('', pageIndex, event.rows || 20, sort);
    } else {
      this.dataSource.load('', pageIndex, event.rows || 20);
    }
  }
}
