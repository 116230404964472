import { inject, Injectable, runInInjectionContext } from '@angular/core';
import { View, ViewContentType, ViewFilter, ViewFilterGroup, ViewSort } from '@app/robaws/domain';
import { MenuItem } from 'primeng/api';
import { InternalServiceMessageService, Path, PathService, ToastService } from '@shared/services';
import { catchError, EMPTY, exhaustMap, finalize, forkJoin, MonoTypeOperatorFunction, Observable, pipe, switchMap, tap } from 'rxjs';
import { ViewService } from '@app/robaws/services/view.service';
import { ComponentStore } from '@ngrx/component-store';
import { ResourceTypeMetadata } from '@shared/domain';
import { UserViewsInfo } from '@app/robaws/domain/UserViewsInfo';
import { filter } from 'rxjs/operators';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { robawsWindow } from '@app/shared/helpers/window.helper';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { AlertHelper } from '@shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewType } from '@app/robaws/domain/ViewType';
import { ViewSettingsDTO } from '@app/robaws/components/dynamic-overview/view-settings/view-settings.component';
import { AppInjector } from '@app/app.module';

export type Tab = {
  view: View;
  isNew: boolean;
};

export type ContextMenuAction = {
  id: string;
  icon: string;
  iconColor: string;
  text: string;
};

export type InitData = {
  resourceType: string;
  viewContentType: ViewContentType;
};

export type AvailableViews = {
  byMe: View[];
  sharedWithMe: View[];
  loading: boolean;
  filter: string;
};

export type ReorderView = {
  oldIndex: number;
  newIndex: number;
};

export type DynamicViewState = {
  // Mandatory inputs
  initialized: boolean;
  resourceType: string;
  viewContentType: ViewContentType;
  contextMenuActions: MenuItem[];

  metadata?: ResourceTypeMetadata;
  metadataPaths?: Path[];

  // View info
  activeTab?: Tab;
  loadedViews: View[];
  availableViews: AvailableViews;
  // View state to sync with the server
  userViewsInfo: UserViewsInfo;
};

export function logOnError<T>(): MonoTypeOperatorFunction<T> {
  let toastService: ToastService;
  runInInjectionContext(AppInjector, () => {
    toastService = inject(ToastService);
  });
  return pipe(
    catchError((err) => {
      toastService?.fireToast('Error while loading overview state');
      console.error('Error while saving overview state', err);
      return EMPTY;
    }),
  );
}

/**
 * Share state between dynamic-view components
 */
@Injectable()
export class DynamicViewStore extends ComponentStore<DynamicViewState> {
  // Selectors
  readonly initialized$ = this.select((s) => s.initialized);
  readonly resourceType$ = this.select(
    this.initialized$,
    this.select((s) => s.resourceType),
    (initialized, resourceType) => (initialized ? resourceType : undefined),
  ).pipe(filter((resourceType): resourceType is string => !!resourceType));

  readonly viewContentType$ = this.select(
    this.initialized$,
    this.select((s) => s.viewContentType),
    (initialized, viewContentType) => (initialized ? viewContentType : undefined),
  ).pipe(filter((viewContentType): viewContentType is ViewContentType => !!viewContentType));

  readonly activeTab$ = this.select((state) => state.activeTab);

  // Updaters
  readonly initialize = this.updater((state, data: InitData) => {
    return {
      ...state,
      ...data,
      initialized: true,
    };
  });

  readonly contextMenuActions = this.updater((state, contextMenuActionsJson: string) => {
    const contextMenuActions: ContextMenuAction[] = JSON.parse(contextMenuActionsJson);

    return {
      ...state,
      contextMenuActions: contextMenuActions.map((action) => {
        return {
          icon: action.icon,
          label: action.text,
          iconStyle: { color: action.iconColor },
          command: () => {
            this.internalServiceMessageService.dispatch('context-menu-action-clicked', {
              viewContentType: this.get((s) => s.viewContentType),
              actionId: action.id,
            });
          },
        };
      }),
    };
  });

  readonly updateCurrentView = this.updater((state, view: Tab | undefined) => {
    return {
      ...state,
      activeTab: view,
    };
  });

  /**
   * Update the sorts for a tab
   * Does not trigger a change in the loaded views
   */
  readonly updateSortsForTab = this.updater((state, data: { id: string; sorts: ViewSort[] }) => {
    const loadedViews = state.loadedViews;
    if (state.activeTab?.view.id === data.id) {
      state.activeTab.view.sorts = data.sorts;
    }

    const view = loadedViews.find((it) => it.id === data.id);
    if (!view) {
      return state;
    }
    view.sorts = data.sorts;
    return state;
  });

  /**
   * Update the additional filters for a tab
   * Does not trigger a change in the loaded views
   */
  readonly updateAdditionalFiltersForTab = this.updater((state, data: { id: string; filters: ViewFilter[] }) => {
    const loadedViews = state.loadedViews;
    if (state.activeTab?.view.id === data.id) {
      state.activeTab.view.additionalFilters = data.filters;
    }

    const view = loadedViews.find((it) => it.id === data.id);
    if (!view) {
      return state;
    }
    view.additionalFilters = data.filters;
    return state;
  });

  /**
   * Update the additional filters for a tab
   * Does not trigger a change in the loaded views
   */
  readonly updateFilterGroupForTab = this.updater((state, data: { id: string; filterGroup: ViewFilterGroup }) => {
    const loadedViews = state.loadedViews;
    if (state.activeTab?.view.id === data.id) {
      state.activeTab.view.filterGroup = data.filterGroup;
      if (data.filterGroup.id) {
        state.activeTab.view.filterGroupId = data.filterGroup.id;
      }
    }

    const view = loadedViews.find((it) => it.id === data.id);
    if (!view) {
      return state;
    }
    view.filterGroup = data.filterGroup;
    if (data.filterGroup.id) {
      view.filterGroupId = data.filterGroup.id;
    }
    return state;
  });

  // Actions (side effects update the status with manual calls)

  readonly loadCurrentViews = this.effect<void>(($) => {
    return $.pipe(
      switchMap(() => {
        const userViewsInfo = this.get((s) => s.userViewsInfo);
        return this.viewService
          .getViewsByIds(
            this.get((s) => s.viewContentType),
            userViewsInfo.activeViews.map((it) => it.viewId),
          )
          .pipe(
            tap((page) => {
              const getActiveViewIndex = (viewId: string): number => {
                return userViewsInfo.activeViews.find((it) => it.viewId === viewId)?.index ?? 0;
              };
              const view = page.items.find((view) => view.id === userViewsInfo.focussedViewId);
              const activeView = view ? { view, isNew: false } : undefined;
              this.patchState({
                loadedViews: page.items.sort((a, b) => getActiveViewIndex(a.id) - getActiveViewIndex(b.id)),
              });
              this.updateCurrentView(activeView);
            }),
            logOnError(),
          );
      }),
    );
  });

  /**
   * Delete a tab
   * Sync with the server
   * Activate the first tab
   */
  readonly deleteTab = this.effect((tab$: Observable<View>) => {
    return tab$.pipe(
      exhaustMap((tab) => {
        if (this.get((s) => s.loadedViews).length <= 1) {
          return EMPTY;
        }
        const userViewsInfo = this.get((s) => s.userViewsInfo);
        const newActiveViews = this.get((s) => s.loadedViews).filter((it) => it.id !== tab.id);
        let currentView = this.get((s) => s.activeTab);
        if (currentView?.view.id === tab.id) {
          currentView = {
            view: newActiveViews[0],
            isNew: false,
          };
        }
        this.patchState({
          userViewsInfo: {
            ...userViewsInfo,
            activeViews: userViewsInfo.activeViews.filter((it) => it.viewId !== tab.id),
          },
          loadedViews: newActiveViews,
        });
        this.openView(currentView?.view);

        // Sync with the server
        return this.viewService
          .updateUserViewsInfo(
            this.get((s) => s.viewContentType),
            this.get((s) => s.userViewsInfo),
          )
          .pipe(logOnError());
      }),
    );
  });

  /**
   * Create a new view
   * Sync with the server
   * Activate the new view
   */
  readonly createNewView = this.effect<void>(($) => {
    return $.pipe(
      switchMap(() => {
        const activeTab = this.get((s) => s.activeTab);
        if (!activeTab) {
          return EMPTY;
        }
        robawsWindow.startLoader();
        return this.viewService.copyView(activeTab.view).pipe(
          switchMap((personalCopy) => {
            const userViewsInfo = this.get((s) => s.userViewsInfo);
            const loadedViews = this.get((s) => s.loadedViews);
            this.patchState({
              userViewsInfo: {
                ...userViewsInfo,
                focussedViewId: personalCopy.id,
                activeViews: [...userViewsInfo.activeViews, { index: loadedViews.length, viewId: personalCopy.id }],
              },
              loadedViews: [...loadedViews, personalCopy],
              activeTab: {
                view: personalCopy,
                isNew: true,
              },
            });

            // Sync with the server
            return this.viewService.updateUserViewsInfo(
              this.get((s) => s.viewContentType),
              this.get((s) => s.userViewsInfo),
            );
          }),
          finalize(() => {
            robawsWindow.stopLoader();
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly loadViewsByMeAndOthers = this.effect<string>((filter$) => {
    return filter$.pipe(
      switchMap((filter) => {
        const viewContentType = this.get((s) => s.viewContentType);
        this.patchState({
          availableViews: {
            ...this.get((s) => s.availableViews),
            filter,
            loading: true,
          },
        });
        return forkJoin([
          this.viewService.getViewsCreatedByCurrentUser(viewContentType, filter, 0, 100, 'name:asc'),
          this.viewService.getViewsCreatedByOtherUsers(viewContentType, filter, 0, 100, 'name:asc'),
        ]).pipe(
          tap(([viewsByMe, viewsByOthers]) => {
            this.patchState({
              availableViews: {
                loading: false,
                byMe: viewsByMe.items,
                sharedWithMe: viewsByOthers.items,
                filter,
              },
            });
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly loadViewsByMeAndOthersLastFilter = this.effect<void>(($) => {
    return $.pipe(
      tap(() => {
        this.loadViewsByMeAndOthers(this.get((s) => s.availableViews.filter));
      }),
    );
  });

  readonly openView = this.effect<View | undefined>((view$) => {
    return view$.pipe(
      switchMap((view) => {
        if (!view) {
          const loadedViews = this.get((s) => s.loadedViews);
          if (loadedViews.length === 0) {
            return EMPTY;
          }
          view = loadedViews[0];
        }
        const viewContentType = this.get((s) => s.viewContentType);
        const userViewsInfo = this.get((s) => s.userViewsInfo);

        if (view.id === userViewsInfo.focussedViewId) {
          return EMPTY;
        }

        const activeViews = !userViewsInfo.activeViews.find((it) => it.viewId === view.id)
          ? [...userViewsInfo.activeViews, { index: this.get((s) => s.loadedViews).length, viewId: view.id }]
          : userViewsInfo.activeViews;

        const updatedUserViewsInfo = {
          ...userViewsInfo,
          focussedViewId: view.id,
          activeViews,
        };
        let loadedViews = this.get((s) => s.loadedViews);
        if (!loadedViews.find((it) => it.id === view.id)) {
          loadedViews = [...loadedViews, view];
        }
        this.patchState({
          userViewsInfo: updatedUserViewsInfo,
          loadedViews,
        });
        this.updateCurrentView({ view, isNew: false });
        return this.viewService.updateUserViewsInfo(viewContentType, updatedUserViewsInfo).pipe(logOnError());
      }),
    );
  });

  readonly openAvailableView = this.effect<View>((view$) => {
    return view$.pipe(
      switchMap((view: View) => {
        return this.viewService
          .findViewByParentViewId(
            this.get((s) => s.viewContentType),
            view,
          )
          .pipe(
            switchMap((personalView) => {
              if (personalView) {
                this.openView(view);
                return EMPTY;
              } else {
                return this.viewService.createCopyWithParentView(view).pipe(
                  tap((personalCopy) => {
                    this.openView(personalCopy);
                  }),
                );
              }
            }),
            logOnError(),
          );
      }),
    );
  });

  readonly deleteView = this.effect<View>((view$) => {
    return view$.pipe(
      switchMap((view) => {
        return this.viewService.deleteView(view.id).pipe(
          tap(() => {
            const userViewsInfo = this.get((s) => s.userViewsInfo);
            this.patchState({
              userViewsInfo: {
                ...userViewsInfo,
                activeViews: userViewsInfo.activeViews.filter((it) => it.viewId !== view.id),
              },
              loadedViews: this.get((s) => s.loadedViews).filter((it) => it.id !== view.id),
            });
            if (this.get((s) => s.activeTab)?.view.id === view.id) {
              this.openView(undefined);
            }
            this.loadCurrentViews();
            this.loadViewsByMeAndOthersLastFilter();
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly patchAvailableViewVisibility = this.effect<View>((view$) => {
    return view$.pipe(
      switchMap((view) => {
        return this.viewService.updateViewVisibility(view.id, view.visibility).pipe(
          tap(() => {
            this.loadCurrentViews();
            this.alertHelper.fireToast(
              'success',
              this.translateService.instant('overviews.settings.visibility-changed-to-' + view.visibility.toLowerCase()),
              RobawsConstants.TOAST_DURATION_SHORT,
            );
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly patchTabOrder = this.effect<ReorderView>((reorderView$) => {
    return reorderView$.pipe(
      switchMap((reOrder) => {
        const loadedViews = this.get((s) => s.loadedViews);
        moveItemInArray(loadedViews, reOrder.oldIndex, reOrder.newIndex);

        const userViewsInfo = this.get((s) => s.userViewsInfo);
        const activeViews = [];
        for (let i = 0; i < loadedViews.length; i++) {
          activeViews.push({ index: i, viewId: loadedViews[i].id });
        }

        this.patchState({
          loadedViews,
          userViewsInfo: {
            ...userViewsInfo,
            activeViews,
          },
        });

        // Sync with the server
        return this.viewService
          .updateUserViewsInfo(
            this.get((s) => s.viewContentType),
            this.get((s) => s.userViewsInfo),
          )
          .pipe(logOnError());
      }),
    );
  });

  readonly changeCurrentViewType = this.effect<ViewType>((viewType$) => {
    return viewType$.pipe(
      switchMap((viewType) => {
        const activeTab = this.get((s) => s.activeTab);
        if (!activeTab) {
          return EMPTY;
        }
        return this.viewService.updateViewType(activeTab.view.id, viewType).pipe(
          tap(() => {
            this.patchState({
              activeTab: {
                ...activeTab,
                view: {
                  ...activeTab.view,
                  type: viewType,
                },
              },
            });
            // Update the viewType for the tab as no load is required
            const loadedViews = this.get((s) => s.loadedViews);
            const view = loadedViews.find((it) => it.id === activeTab.view.id);
            if (view) {
              view.type = viewType;
            }
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly updateViewSettings = this.effect<ViewSettingsDTO>((viewSettingsDTO$) => {
    return viewSettingsDTO$.pipe(
      switchMap((viewSettings) => {
        const activeTab = this.get((s) => s.activeTab);
        if (!activeTab) {
          return EMPTY;
        }
        return forkJoin([
          this.viewService.updateViewNameAndVisibility(activeTab.view.id, viewSettings.name, viewSettings.visibility),
          this.viewService
            .updateColumns(
              activeTab.view.id,
              viewSettings.columns.map((it) => ({ dataPath: it })),
            )
            .pipe(
              switchMap(() => {
                // resetting the table and column widths to let the table recalculate the column widths
                return this.viewService.updateTableAndColumnWidths(activeTab.view.id, null, null);
              }),
            ),
        ]).pipe(
          tap(() => {
            this.loadCurrentViews();
          }),
          logOnError(),
        );
      }),
    );
  });

  // Effects (side effects that automatically update the state)

  /**
   * Fetch metadata for the current resource type
   */
  readonly fetchMedata = this.effect<void>(() => {
    return this.resourceType$.pipe(
      switchMap((resourceType) => {
        return this.dynamicResourceTypeProvider.getMetadata(resourceType).pipe(
          tap((metadata) => {
            this.patchState({
              metadata,
            });
          }),
          logOnError(),
        );
      }),
    );
  });

  /**
   * Fetches the available views for the current user and activates the previously active view
   */
  readonly fetchViewsAndActivate = this.effect<void>(() => {
    return this.viewContentType$.pipe(
      switchMap((viewContentType) => {
        return this.viewService.getUserViewsInfo(viewContentType).pipe(
          tap((userViewsInfo) => {
            this.patchState({
              userViewsInfo,
            });
            this.loadCurrentViews();
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly fetchMetadataPaths = this.effect<void>(() => {
    return this.resourceType$.pipe(
      switchMap((resourceType) => {
        return this.pathService.getPaths(this.dynamicResourceTypeProvider, resourceType, false, true, true, true, true, true).pipe(
          tap((paths) => this.patchState({ metadataPaths: paths })),
          logOnError(),
        );
      }),
    );
  });

  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('VIEW');

  constructor(
    private internalServiceMessageService: InternalServiceMessageService,
    private pathService: PathService,
    private viewService: ViewService,
    private alertHelper: AlertHelper,
    private translateService: TranslateService,
  ) {
    super({
      initialized: false,
      resourceType: '',
      viewContentType: 'CLIENT',
      contextMenuActions: [],
      userViewsInfo: {} as UserViewsInfo,
      loadedViews: [],
      availableViews: {
        loading: false,
        byMe: [],
        sharedWithMe: [],
        filter: '',
      },
    });
  }
}
