import { ConsoleApiName } from '../../tools/display';
import { NO_ERROR_STACK_PRESENT_MESSAGE } from '../error/error';
import { toStackTraceString } from '../../tools/stackTrace/handlingStack';
import { getExperimentalFeatures } from '../../tools/experimentalFeatures';
import { INTAKE_SITE_STAGING, INTAKE_SITE_US1_FED } from '../configuration';
import { Observable } from '../../tools/observable';
import { timeStampNow } from '../../tools/utils/timeUtils';
import { displayIfDebugEnabled, startMonitorErrorCollection } from '../../tools/monitor';
import { sendToExtension } from '../../tools/sendToExtension';
import { startsWith, arrayFrom, includes, assign } from '../../tools/utils/polyfills';
import { performDraw } from '../../tools/utils/numberUtils';
import { jsonStringify } from '../../tools/serialisation/jsonStringify';
import { combine } from '../../tools/mergeInto';
import { computeStackTrace } from '../../tools/stackTrace/computeStackTrace';
import { getConnectivity } from '../connectivity';
import { createBoundedBuffer } from '../../tools/boundedBuffer';
import { TelemetryType } from './rawTelemetryEvent.types';
var ALLOWED_FRAME_URLS = ['https://www.datadoghq-browser-agent.com', 'https://www.datad0g-browser-agent.com', 'https://d3uc069fcn7uxw.cloudfront.net', 'https://d20xtzwzcl0ceb.cloudfront.net', 'http://localhost', '<anonymous>'];
var TELEMETRY_EXCLUDED_SITES = [INTAKE_SITE_US1_FED];
// eslint-disable-next-line local-rules/disallow-side-effects
var preStartTelemetryBuffer = createBoundedBuffer();
var onRawTelemetryEventCollected = function (event) {
  preStartTelemetryBuffer.add(function () {
    return onRawTelemetryEventCollected(event);
  });
};
export function startTelemetry(telemetryService, configuration) {
  var _a;
  var contextProvider;
  var observable = new Observable();
  var alreadySentEvents = new Set();
  var telemetryEnabled = !includes(TELEMETRY_EXCLUDED_SITES, configuration.site) && performDraw(configuration.telemetrySampleRate);
  var telemetryEnabledPerType = (_a = {}, _a[TelemetryType.log] = telemetryEnabled, _a[TelemetryType.configuration] = telemetryEnabled && performDraw(configuration.telemetryConfigurationSampleRate), _a[TelemetryType.usage] = telemetryEnabled && performDraw(configuration.telemetryUsageSampleRate), _a);
  var runtimeEnvInfo = getRuntimeEnvInfo();
  onRawTelemetryEventCollected = function (rawEvent) {
    var stringifiedEvent = jsonStringify(rawEvent);
    if (telemetryEnabledPerType[rawEvent.type] && alreadySentEvents.size < configuration.maxTelemetryEventsPerPage && !alreadySentEvents.has(stringifiedEvent)) {
      var event_1 = toTelemetryEvent(telemetryService, rawEvent, runtimeEnvInfo);
      observable.notify(event_1);
      sendToExtension('telemetry', event_1);
      alreadySentEvents.add(stringifiedEvent);
    }
  };
  startMonitorErrorCollection(addTelemetryError);
  function toTelemetryEvent(telemetryService, event, runtimeEnvInfo) {
    return combine({
      type: 'telemetry',
      date: timeStampNow(),
      service: telemetryService,
      version: "5.28.0",
      source: 'browser',
      _dd: {
        format_version: 2
      },
      telemetry: combine(event, {
        runtime_env: runtimeEnvInfo,
        connectivity: getConnectivity()
      }),
      experimental_features: arrayFrom(getExperimentalFeatures())
    }, contextProvider !== undefined ? contextProvider() : {});
  }
  return {
    setContextProvider: function (provider) {
      contextProvider = provider;
    },
    observable: observable,
    enabled: telemetryEnabled
  };
}
function getRuntimeEnvInfo() {
  return {
    is_local_file: window.location.protocol === 'file:',
    is_worker: 'WorkerGlobalScope' in self
  };
}
export function startFakeTelemetry() {
  var events = [];
  onRawTelemetryEventCollected = function (event) {
    events.push(event);
  };
  return events;
}
// need to be called after telemetry context is provided and observers are registered
export function drainPreStartTelemetry() {
  preStartTelemetryBuffer.drain();
}
export function resetTelemetry() {
  preStartTelemetryBuffer = createBoundedBuffer();
  onRawTelemetryEventCollected = function (event) {
    preStartTelemetryBuffer.add(function () {
      return onRawTelemetryEventCollected(event);
    });
  };
}
/**
 * Avoid mixing telemetry events from different data centers
 * but keep replicating staging events for reliability
 */
export function isTelemetryReplicationAllowed(configuration) {
  return configuration.site === INTAKE_SITE_STAGING;
}
export function addTelemetryDebug(message, context) {
  displayIfDebugEnabled(ConsoleApiName.debug, message, context);
  onRawTelemetryEventCollected(assign({
    type: TelemetryType.log,
    message: message,
    status: "debug" /* StatusType.debug */
  }, context));
}
export function addTelemetryError(e, context) {
  onRawTelemetryEventCollected(assign({
    type: TelemetryType.log,
    status: "error" /* StatusType.error */
  }, formatError(e), context));
}
export function addTelemetryConfiguration(configuration) {
  onRawTelemetryEventCollected({
    type: TelemetryType.configuration,
    configuration: configuration
  });
}
export function addTelemetryUsage(usage) {
  onRawTelemetryEventCollected({
    type: TelemetryType.usage,
    usage: usage
  });
}
export function formatError(e) {
  if (e instanceof Error) {
    var stackTrace = computeStackTrace(e);
    return {
      error: {
        kind: stackTrace.name,
        stack: toStackTraceString(scrubCustomerFrames(stackTrace))
      },
      message: stackTrace.message
    };
  }
  return {
    error: {
      stack: NO_ERROR_STACK_PRESENT_MESSAGE
    },
    message: "".concat("Uncaught" /* NonErrorPrefix.UNCAUGHT */, " ").concat(jsonStringify(e))
  };
}
export function scrubCustomerFrames(stackTrace) {
  stackTrace.stack = stackTrace.stack.filter(function (frame) {
    return !frame.url || ALLOWED_FRAME_URLS.some(function (allowedFrameUrl) {
      return startsWith(frame.url, allowedFrameUrl);
    });
  });
  return stackTrace;
}
