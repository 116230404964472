import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ViewContentType, ViewFilter, ViewFilterGroup } from '@app/robaws/domain';
import { ViewFilterEditorComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-filter-editor/view-filter-editor.component';
import { ResourceTypeMetadata } from '@shared/domain';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';

@Component({
  selector: 'view-filter-group-editor',
  templateUrl: 'view-filter-group-editor.component.html',
  styleUrls: ['view-filter-group-editor.component.scss'],
  standalone: true,
  imports: [ViewFilterEditorComponent, MatIcon, TranslateModule, NgIf, CdkDrag, CdkDropList, CdkDragHandle, DeleteIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewFilterGroupEditorComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public viewContentType: ViewContentType;
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;
  @Input({ required: true })
  public viewFilterGroup: ViewFilterGroup;
  @Input()
  public isParentFilterGroup = false;
  @Output()
  public clearAllClicked = new EventEmitter<void>();

  @Output()
  public filterGroupDeleted = new EventEmitter<void>();

  public ngOnInit(): void {
    this.initializeViewFilterGroup();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewFilterGroup']) {
      this.viewFilterGroup = changes['viewFilterGroup'].currentValue;

      this.initializeViewFilterGroup();
    }
  }

  public clearAll(): void {
    this.viewFilterGroup.filters = [];
    this.viewFilterGroup.filterGroups = [];
    this.addFilterGroup();
    this.clearAllClicked.emit();
  }

  protected switchOperator(): void {
    if (this.viewFilterGroup.operator === 'AND') {
      this.viewFilterGroup.operator = 'OR';
    } else {
      this.viewFilterGroup.operator = 'AND';
    }
  }

  protected addFilterGroup(): void {
    this.viewFilterGroup.filterGroups.push({
      operator: 'AND',
      filters: [
        {
          path: '',
          operator: 'EQUALS',
          value: '',
        },
      ],
      filterGroups: [],
    });
  }

  protected addFilter(): void {
    this.viewFilterGroup.filters.push({
      path: '',
      operator: 'EQUALS',
      value: '',
    });
  }

  protected deleteFilter(filter: ViewFilter): void {
    this.viewFilterGroup.filters = this.viewFilterGroup.filters.filter((f) => f !== filter);
    this.checkEmpty();
  }

  protected onFilterGroupDeleted(viewFilterGroup: ViewFilterGroup): void {
    this.viewFilterGroup.filterGroups = this.viewFilterGroup.filterGroups.filter((fg) => fg !== viewFilterGroup);
    this.checkEmpty();
  }

  protected dropFilter(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.viewFilterGroup.filters, event.previousIndex, event.currentIndex);
  }

  protected isEmpty(): boolean {
    return this.viewFilterGroup.filters.length === 0 && this.viewFilterGroup.filterGroups.length === 0;
  }

  private checkEmpty(): void {
    if (this.isEmpty()) {
      if (!this.isParentFilterGroup) {
        this.filterGroupDeleted.emit();
      }
    }
  }

  private initializeViewFilterGroup(): void {
    if (!this.isParentFilterGroup) {
      return;
    }
    if (!this.viewFilterGroup.operator) {
      this.viewFilterGroup.operator = 'OR';
    }
    if (!this.viewFilterGroup.filterGroups || this.viewFilterGroup.filterGroups.length === 0) {
      this.addFilterGroup();
    }
  }
}
