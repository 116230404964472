import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ViewFilter, ViewFilterGroup } from '@app/robaws/domain';
import { DynamicViewStore, logOnError, Tab } from '@app/robaws/components/dynamic-overview/dynamic-view.store';
import { EMPTY, forkJoin, Observable, scan, switchMap, tap, withLatestFrom } from 'rxjs';
import { ViewQuickFiltersService } from '@app/robaws/services/view-quick-filters.service';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { ViewService } from '@app/robaws/services/view.service';
import { ViewFilterGroupService } from '@app/robaws/services/view-filter-group.service';
import { UrlService } from '@shared/services';
import { DynamicViewTableStore, TableFilters } from '@app/robaws/components/dynamic-overview/dynamic-view-table.store';

export type FilterState = {
  quickFilterPaths: string[];
  overrideWasCleared: boolean;

  additionalFilters: ViewFilter[];
  overrideFilters: ViewFilter[];
  searchText: string;
  viewFilterGroup: ViewFilterGroup;
  previousViewFilterGroup?: ViewFilterGroup; // State is synced through the backend to fetch the filters. Track changes to update the table filters if they changed
  previousAdditionalFilters?: ViewFilter[];
};

const hasDeletedFilter = (filters: ViewFilter[] | undefined) => {
  if (!filters) {
    return false;
  }
  return filters.some((filter) => filter.path === RobawsConstants.IGNORE_DELETED_AT);
};

const hasArchivedFilter = (filters: ViewFilter[] | undefined) => {
  if (!filters) {
    return false;
  }
  return filters.some((filter) => filter.path === RobawsConstants.IGNORE_ARCHIVED_AT);
};

const deepCopyFilterGroup = (filterGroup: ViewFilterGroup): ViewFilterGroup => {
  return {
    id: filterGroup.id,
    operator: filterGroup.operator,
    filters: filterGroup.filters.map((filter) => ({ ...filter })),
    filterGroups: filterGroup.filterGroups.map((filterGroup) => deepCopyFilterGroup(filterGroup)),
  };
};

const deepCopyFilters = (filters: ViewFilter[]): ViewFilter[] => {
  return filters.map((filter) => ({ ...filter }));
};

@Injectable()
export class DynamicViewFilterStore extends ComponentStore<FilterState> {
  // Selectors

  readonly selectTotalFilterCount$ = this.select(
    this.select((state) => state.overrideFilters),
    this.select((state) => state.additionalFilters),
    this.select((state) => state.viewFilterGroup),
    (overrideFilters, additionalFilters, viewFilterGroup) => {
      const getTotalFiltersCount = (viewFilterGroup: ViewFilterGroup): number => {
        return (
          viewFilterGroup.filters.filter((it) => it.path && it.path !== '' && it.operator).length +
          viewFilterGroup.filterGroups.reduce((acc, filterGroup) => acc + getTotalFiltersCount(filterGroup), 0)
        );
      };

      return (
        getTotalFiltersCount(viewFilterGroup) +
        additionalFilters.filter((it) => it.path === RobawsConstants.IGNORE_ARCHIVED_AT || it.path === RobawsConstants.IGNORE_DELETED_AT).length +
        overrideFilters.filter((it) => it.path && it.path !== '' && it.operator).length
      );
    },
  );

  readonly selectHasDeletedFilter$ = this.select(
    this.select((s) => s.additionalFilters),
    (filters) => hasDeletedFilter(filters),
  );
  readonly selectHasArchivedFilter$ = this.select(
    this.select((s) => s.additionalFilters),
    (filters) => hasArchivedFilter(filters),
  );

  // Updaters (pure functions that update the state)
  updateDeleteOverrideFilter = this.updater((state, filter: ViewFilter) => {
    const emptyBefore = state.overrideFilters.length === 0;
    const overrideFilters = state.overrideFilters.filter((f) => f !== filter);
    return {
      ...state,
      overrideFilters,
      overrideWasCleared: !emptyBefore && overrideFilters.length === 0,
    };
  });

  readonly updateToggleArchivedFilter = this.updater((state, toggle: boolean) => {
    if (toggle) {
      if (!hasArchivedFilter(state.additionalFilters)) {
        return {
          ...state,
          additionalFilters: [
            ...state.additionalFilters,
            {
              path: RobawsConstants.IGNORE_ARCHIVED_AT,
              operator: 'IS_NOT_EMPTY',
              value: '',
            },
          ],
        };
      }
      return state;
    }
    return {
      ...state,
      additionalFilters: state.additionalFilters.filter((filter) => filter.path !== RobawsConstants.IGNORE_ARCHIVED_AT),
    };
  });

  readonly updateToggleDeletedFilter = this.updater((state, toggle: boolean) => {
    if (toggle) {
      if (!hasDeletedFilter(state.additionalFilters)) {
        return {
          ...state,
          additionalFilters: [
            ...state.additionalFilters,
            {
              path: RobawsConstants.IGNORE_DELETED_AT,
              operator: 'IS_NOT_EMPTY',
              value: '',
            },
          ],
        };
      }
      return state;
    }
    return {
      ...state,
      additionalFilters: state.additionalFilters.filter((filter) => filter.path !== RobawsConstants.IGNORE_DELETED_AT),
    };
  });

  readonly updateClearAll = this.updater((state) => {
    if (hasDeletedFilter(state.additionalFilters)) {
      this.updateToggleDeletedFilter(false);
    }
    if (hasArchivedFilter(state.additionalFilters)) {
      this.updateToggleArchivedFilter(false);
    }

    const emptyBefore = state.overrideFilters.length === 0;
    return {
      ...state,
      overrideFilters: [],
      overrideWasCleared: !emptyBefore,
    };
  });

  // Actions (side effects update the status with manual calls)
  readonly changeOverrideFilterJson = this.effect<string>((filtersJson$) => {
    return filtersJson$.pipe(
      tap((filtersJson) => {
        // Every time the view loads, the setter is called with an empty array and this causes multiple requests
        if (filtersJson === JSON.stringify(this.get().overrideFilters)) {
          return;
        }
        const filters = JSON.parse(filtersJson) as ViewFilter[];
        this.patchState({ overrideFilters: filters });
        this.dynamicViewTableStore.updateTableFilters({ overrideFilters: filters });
      }),
    );
  });

  changeSearchText = this.effect<string>((text$) => {
    return text$.pipe(
      tap((text) => {
        if (text === this.get((s) => s.searchText)) {
          return;
        }
        this.patchState({ searchText: text });
        this.dynamicViewTableStore.updateTableFilters({ searchText: text });
      }),
    );
  });

  readonly readSearchTextFromUrl = this.effect<void>(($) => {
    return $.pipe(
      tap(() => {
        const hash = window.location.hash;
        const split = hash.split('?');
        const urlSearchParams = new URLSearchParams(decodeURIComponent(split[1]));
        const searchText = urlSearchParams.get('search') ?? '';
        this.changeSearchText(searchText);
      }),
    );
  });

  readonly changeAdditionalFilters = this.effect<ViewFilter[]>((filters$) => {
    return filters$.pipe(
      tap((filters) => {
        this.patchState({ additionalFilters: filters });
      }),
      withLatestFrom(this.dynamicViewStore.activeTab$),
      switchMap(([filters, activeTab]) => {
        const activeViewId = activeTab?.view.id;
        if (!activeViewId) {
          return EMPTY;
        }
        return this.viewService.updateAdditionalFilters(activeViewId, filters).pipe(
          tap(() => {
            this.dynamicViewTableStore.updateTableFilters({
              viewId: activeViewId,
              additionalFilters: filters,
            });
            this.dynamicViewStore.updateAdditionalFiltersForTab({ id: activeViewId, filters });
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly saveAllFilters = this.effect<void>(($) => {
    return $.pipe(
      withLatestFrom(this.dynamicViewStore.activeTab$, this.dynamicViewStore.viewContentType$),
      // eslint-disable-next-line no-unused-vars
      switchMap(([_, activeTab, viewContentType]) => {
        const activeViewId = activeTab?.view.id;
        const filterGroup = this.get((s) => s.viewFilterGroup);

        const groupId = filterGroup.id;
        if (!activeViewId || !groupId) {
          return EMPTY;
        }

        const previousFilterGroup = this.get((s) => s.previousViewFilterGroup);
        const filterGroupChanged = JSON.stringify(filterGroup) !== JSON.stringify(previousFilterGroup);
        const saveFilterGroup: Observable<void>[] = filterGroupChanged
          ? [
              this.viewFilterGroupService.updateViewFilterGroupFilterGroups(groupId, filterGroup.filterGroups),
              this.viewFilterGroupService.updateViewFilterGroupFilters(groupId, filterGroup.filters),
            ]
          : [];

        const additionalFilters = this.get((s) => s.additionalFilters);
        const previousAdditionalFilters = this.get((s) => s.previousAdditionalFilters);
        const additionalFiltersChanged = JSON.stringify(additionalFilters) !== JSON.stringify(previousAdditionalFilters);
        const saveAdditionalFilters = additionalFiltersChanged ? [this.viewService.updateAdditionalFilters(activeViewId, additionalFilters)] : [];

        return forkJoin([...saveFilterGroup, ...saveAdditionalFilters]).pipe(
          tap(() => {
            let additionalFiltersUpdate = additionalFilters;
            if (additionalFiltersChanged) {
              additionalFiltersUpdate = deepCopyFilters(additionalFilters);
              this.patchState({ previousAdditionalFilters: additionalFiltersUpdate });
            }
            const tableFilterChange: Partial<TableFilters> = {
              viewId: activeViewId,
              overrideFilters: this.get((s) => s.overrideFilters),
              additionalFilters: additionalFiltersUpdate,
            };
            if (filterGroupChanged) {
              const copyFilterGroup = deepCopyFilterGroup(filterGroup);
              this.patchState({
                previousViewFilterGroup: filterGroup,
                viewFilterGroup: copyFilterGroup,
              });
              this.dynamicViewTableStore.updateTableFiltersAndFilterGroupCount(tableFilterChange);
              this.dynamicViewStore.updateFilterGroupForTab({ id: activeViewId, filterGroup: copyFilterGroup });
            } else {
              this.dynamicViewTableStore.updateTableFilters(tableFilterChange);
            }
            this.dynamicViewStore.updateAdditionalFiltersForTab({ id: activeViewId, filters: additionalFilters });
            if (this.get().overrideWasCleared) {
              this.patchState({ overrideWasCleared: false });
              this.urlService.navigateToDynamicOverview(viewContentType);
            }
          }),
          logOnError(),
        );
      }),
    );
  });

  // Effects (side effects that automatically update the state)

  readonly syncFilterWithView = this.effect<void>(() => {
    return this.dynamicViewStore.activeTab$.pipe(
      tap((tab) => {
        let filterGroup = tab?.view.filterGroup;
        if (!filterGroup) {
          filterGroup = {
            operator: 'AND',
            filters: [],
            filterGroups: [],
          };
        }
        const additionalFilters = tab?.view.additionalFilters ?? [];
        this.patchState({
          additionalFilters: additionalFilters,
          previousAdditionalFilters: deepCopyFilters(additionalFilters),
          viewFilterGroup: filterGroup,
          previousViewFilterGroup: deepCopyFilterGroup(filterGroup),
        });
      }),
      scan((previous: Tab | undefined, current: Tab | undefined) => {
        if (!!previous && !!current) {
          this.changeSearchText('');
        }
        return current;
      }, undefined),
    );
  });

  readonly fetchQuickFilterPaths = this.effect<void>(() => {
    return this.dynamicViewStore.viewContentType$.pipe(
      switchMap((viewContentType) => {
        return this.viewQuickFiltersService.getQuickFilterPaths(viewContentType).pipe(
          tap((paths) => {
            this.patchState({ quickFilterPaths: paths });
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly pushSearchTextToBrowserHistory = this.effect<void>(() => {
    return this.select((state) => state.searchText).pipe(
      tap((searchText) => {
        const hash = window.location.hash;
        const split = hash.split('?');
        const urlSearchParams = new URLSearchParams(split[1]);
        if (urlSearchParams.get('search') === searchText) {
          return;
        }
        urlSearchParams.set('search', searchText);
        if (searchText === '') {
          window.history.replaceState({}, '', `${split[0]}`);
        } else {
          window.history.replaceState({}, '', `${split[0]}?${urlSearchParams}`);
        }
      }),
    );
  });

  constructor(
    private dynamicViewStore: DynamicViewStore,
    private dynamicViewTableStore: DynamicViewTableStore,
    private viewQuickFiltersService: ViewQuickFiltersService,
    private viewService: ViewService,
    private viewFilterGroupService: ViewFilterGroupService,
    private urlService: UrlService,
  ) {
    super({
      additionalFilters: [],
      overrideFilters: [],
      searchText: '',
      viewFilterGroup: {
        operator: 'AND',
        filters: [],
        filterGroups: [],
      },
      quickFilterPaths: [],
      overrideWasCleared: false,
    });
  }
}
