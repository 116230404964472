import { ViewColumnVO, ViewDataRowWithTextColor } from '@app/robaws/components/dynamic-overview/dynamic-overview.component';
import { SortMeta } from 'primeng/api';
import { ViewFilter, ViewSort } from '@app/robaws/domain';
import { ComponentStore } from '@ngrx/component-store';
import { DynamicViewStore, logOnError } from '@app/robaws/components/dynamic-overview/dynamic-view.store';
import { Injectable } from '@angular/core';
import { combineLatest, concat, distinctUntilChanged, EMPTY, Observable, of, switchMap, tap } from 'rxjs';
import { DataType } from '@shared/domain';
import { filter, map } from 'rxjs/operators';
import { ViewService } from '@app/robaws/services/view.service';
import { ViewSortCreateDTO } from '@app/robaws/domain/ViewSortCreateDTO';

export type TableFilters = {
  viewId: string;
  columns: ViewColumnVO[];
  sorts: SortMeta[];
  previousSorts: SortMeta[];
  searchText: string;
  overrideFilters?: ViewFilter[];
  additionalFilters?: ViewFilter[];
  filterGroupChangeCount: number;
};

export type TablePagination = {
  pageSize: number;
  currentPage: number;
};

export type TableState = {
  tableFilters: TableFilters; // Flushed filter state
  tablePagination: TablePagination;
  tableData: TableData;
  tableLayout?: TableLayout;

  selectedItemIds?: string[];
  fetchCounter: number;
};

export type TableData = {
  loading: boolean;
  totalItems: number;
  rows: ViewDataRowWithTextColor[];
};

export type TableLayout = {
  tableWidth?: number;
  columnWidths?: string;
};

function calculateTextColorBasedOnBackground(hex: string | undefined): 'black' | 'white' {
  if (!hex) {
    return 'black';
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const [red, green, blue] = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];

    return red * 0.299 + green * 0.587 + blue * 0.114 > 150 ? 'black' : 'white';
  }

  return 'black';
}

function checkIfSortsChanged(previousSorts: SortMeta[] | null | undefined, newSorts: SortMeta[] | null | undefined): boolean {
  if (!newSorts && !previousSorts) {
    return false;
  }
  if (!previousSorts) {
    return true;
  }
  if (!newSorts) {
    return true;
  }

  if (newSorts.length !== previousSorts.length) {
    return true;
  }

  for (let i = 0; i < newSorts.length; i++) {
    if (newSorts[i].field !== previousSorts[i].field || newSorts[i].order !== previousSorts[i].order) {
      return true;
    }
  }

  return false;
}

function convertViewSortsToSortMeta(viewSorts: ViewSort[]): SortMeta[] {
  return viewSorts.map((sort) => {
    return {
      field: sort.path,
      order: sort.sortDirection === 'ASC' ? 1 : -1,
    };
  });
}

@Injectable()
export class DynamicViewTableStore extends ComponentStore<TableState> {
  // Util
  readonly _loadPage = (tableFilters: TableFilters, tablePagination: TablePagination, showLoad: boolean): Observable<Partial<TableData>> => {
    let loading$: Observable<Partial<TableData>> = of({ loading: true });
    if (!showLoad) {
      loading$ = EMPTY;
    }
    if (!tableFilters || !tableFilters.viewId) {
      return loading$;
    }
    const load$: Observable<TableData> = this.viewService
      .getViewData(
        tableFilters.viewId,
        tableFilters.searchText,
        tablePagination.currentPage,
        tablePagination.pageSize,
        tableFilters.overrideFilters ?? [],
      )
      .pipe(
        map((data) => {
          const tableData: TableData = {
            loading: false,
            totalItems: data.totalRows,
            rows: data.rows.map((it) => {
              const textColor = calculateTextColorBasedOnBackground(it.color);
              return { ...it, textColor };
            }),
          };
          return tableData;
        }),
        tap((tableData) => {
          // Update selected items to only match selections within current page
          const selectedItemIds = this.get((s) => s.selectedItemIds);
          const newSelectedItems = tableData.rows.filter((row) => selectedItemIds?.includes(row.id));
          if (JSON.stringify(newSelectedItems) === JSON.stringify(this.get((s) => s.tableData.rows))) {
            return;
          }
          this.updateSelectedItemIds(newSelectedItems.map((it) => it.id));
        }),
      );
    return concat(loading$, load$).pipe(
      tap((tableData) => {
        const currentTableData = this.get((s) => s.tableData);
        this.patchState({
          tableData: {
            ...currentTableData,
            ...tableData,
          },
        });
      }),
      logOnError(),
    );
  };

  // Updaters
  readonly updateTableFilters = this.updater((state, tableFilters: Partial<TableFilters>) => {
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters,
      },
    };
  });

  readonly updateTableFiltersAndFilterGroupCount = this.updater((state, tableFilters: Partial<TableFilters>) => {
    const currentFilterGroupChangeCount = state.tableFilters.filterGroupChangeCount;
    return {
      ...state,
      tableFilters: {
        ...state.tableFilters,
        ...tableFilters,
        filterGroupChangeCount: currentFilterGroupChangeCount + 1,
      },
    };
  });

  readonly updatePagination = this.updater((state, tablePagination: Partial<TablePagination>) => {
    const currentPagination = state.tablePagination;
    if (currentPagination.currentPage !== tablePagination.currentPage || currentPagination.pageSize !== tablePagination.pageSize) {
      return {
        ...state,
        tablePagination: {
          ...state.tablePagination,
          ...tablePagination,
        },
      };
    }
    return state;
  });

  readonly updateTableLayout = this.updater((state, tableLayout: TableLayout) => {
    return {
      ...state,
      tableLayout: tableLayout,
    };
  });

  readonly updateSelectedItemIds = this.updater((state, selectedItemIds: string[]) => {
    return {
      ...state,
      selectedItemIds,
    };
  });

  // Actions
  readonly changeTableLayout = this.effect<TableLayout>((tableLayout$) => {
    return tableLayout$.pipe(
      switchMap((tableLayout) => {
        const viewId = this.get((s) => s.tableFilters).viewId;
        return this.viewService.updateTableAndColumnWidths(viewId, tableLayout.tableWidth ?? null, tableLayout.columnWidths ?? null).pipe(
          tap(() => {
            this.updateTableLayout(tableLayout);
          }),
          logOnError(),
        );
      }),
    );
  });

  readonly refreshTable = this.effect<void>(($) => {
    return $.pipe(
      switchMap(() => {
        return this._loadPage(
          this.get((s) => s.tableFilters),
          this.get((s) => s.tablePagination),
          false,
        );
      }),
    );
  });

  readonly refreshTableWithLoader = this.effect<void>(($) => {
    return $.pipe(
      switchMap(() => {
        return this._loadPage(
          this.get((s) => s.tableFilters),
          this.get((s) => s.tablePagination),
          true,
        );
      }),
    );
  });

  readonly updateSorts = this.effect<SortMeta[]>((sorts$) => {
    return sorts$.pipe(
      filter((sorts) => checkIfSortsChanged(this.get((s) => s.tableFilters).previousSorts, sorts)),
      switchMap((sorts) => {
        if (sorts.length === 0) {
          return EMPTY;
        }

        const viewSort: ViewSortCreateDTO[] = sorts
          .map((sort) => {
            return {
              path: sort.field,
              sortDirection: sort.order === 1 ? 'ASC' : 'DESC',
            } as ViewSortCreateDTO;
          })
          .filter((it) => it.path && it.path.length > 0);

        const viewId = this.get((s) => s.tableFilters).viewId;
        return this.viewService.updateSorts(viewId, viewSort).pipe(
          tap((viewSorts) => {
            const currentSorts = this.get((s) => s.tableFilters).sorts;
            this.updateTableFilters({
              sorts: convertViewSortsToSortMeta(viewSorts),
              previousSorts: currentSorts,
            });
            this.dynamicViewStore.updateSortsForTab({ id: viewId, sorts: viewSorts });
          }),
          logOnError(),
        );
      }),
    );
  });

  // Effects (side effects that automatically update the state)
  readonly loadPage = this.effect<void>(() => {
    return combineLatest([this.select((state) => state.tableFilters), this.select((state) => state.tablePagination)]).pipe(
      distinctUntilChanged((previous, current) => {
        return JSON.stringify(previous) === JSON.stringify(current);
      }),
      switchMap(([tableFilters, tablePagination]) => {
        return this._loadPage(tableFilters, tablePagination, true);
      }),
    );
  });

  readonly syncTableFilterWithCurrentView = this.effect<void>(() => {
    return combineLatest([this.dynamicViewStore.activeTab$, this.dynamicViewStore.select((state) => state.metadataPaths)]).pipe(
      tap(([tab, metadataPaths]) => {
        if (!tab?.view || tab?.view.type !== 'TABLE' || !metadataPaths) {
          return;
        }
        const currentView = tab?.view;
        const searchText = '';
        const viewColumns = currentView.columns.map((column) => {
          const path = metadataPaths.find((p) => p.path === column.dataPath);
          const dataType = path?.dataType ?? DataType.TEXT;
          const sortable = path?.sortable ?? false;

          return {
            path: column.dataPath,
            name: path?.displayNameDeep ?? column.dataPath,
            sortable: dataType !== DataType.COMPLEX && sortable,
            primary: path?.primary ?? false,
          };
        });
        const sorts = convertViewSortsToSortMeta(currentView.sorts);
        const previousSorts = [...sorts];
        this.updateTableFilters({
          viewId: currentView.id,
          columns: viewColumns,
          sorts,
          previousSorts,
          searchText,
        });
        this.updatePagination({
          currentPage: 0,
        });
        this.updateTableLayout({
          tableWidth: currentView.tableWidth,
          columnWidths: currentView.columnWidths,
        });
        this.patchState({
          fetchCounter: this.get((s) => s.fetchCounter) + 1,
        });
      }),
    );
  });

  constructor(
    private dynamicViewStore: DynamicViewStore,
    private viewService: ViewService,
  ) {
    super({
      tableFilters: {
        viewId: '',
        columns: [],
        sorts: [],
        previousSorts: [],
        searchText: '',
        filterGroupChangeCount: 0,
      },
      tablePagination: {
        pageSize: 20,
        currentPage: 0,
      },
      tableData: {
        loading: false,
        totalItems: 0,
        rows: [],
      },
      fetchCounter: -1,
    });
  }
}
