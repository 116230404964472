import { ChangeDetectionStrategy, Component, computed, model } from '@angular/core';

@Component({
  selector: 'robaws-ng-switch',
  templateUrl: 'robaws-ng-switch.component.html',
  styleUrls: ['robaws-ng-switch.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RobawsNgSwitchComponent {
  public value = model.required<any>();
  protected booleanValue = computed(() => {
    const value = this.value();

    if (value === null || value === undefined) {
      return false;
    }

    return value === 'true' || value === true;
  });

  protected switch(): void {
    this.value.set(this.booleanValue() ? 'false' : 'true');
  }
}
