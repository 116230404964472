@if (this.currentViewState(); as viewState) {
  <div class="quick-filter">
    <dynamic-type-input [metadataProviderType]="'VIEW'"
                        [metadata]="viewState.currentResourceTypeMetadata"
                        [value]="this.currentValue()"
                        [inputData]="viewState.currentInputData"
                        [inputType]="viewState.currentInputType"
                        [floatingLabel]="false"
                        [allowClear]="false"
                        [openInDropdown]="true"
                        [resourceEntityProvider]="this.resourceEntityProvider"
                        (valueChange)="this.onValueChange($event)">

      <ng-template robawsNgTemplate="input" let-onClick>
        <div class="input-container"
             tooltipPosition="top"
             [pTooltip]="viewState.currentPath.displayNameDeep"
             [attr.data-has-value]="(this.currentValue() ?? '').length > 0"
             (click)="onClick($event)">
        <span class="input-label">
          @if (currentValueCount() > 0) {
            {{ '(' + currentValueCount() + ') ' + viewState.currentPath.displayName }}
          } @else {
            {{ viewState.currentPath.displayName }}
          }
        </span>

          @if ((this.currentValue() ?? '').length > 0) {
            <mat-icon fontIcon="close_small" class="quick-filter-clear-icon" (click)="this.clear()"/>
          }
          <mat-icon fontIcon="arrow_drop_down" class="quick-filter-dropdown-icon"/>
        </div>
      </ng-template>

    </dynamic-type-input>
  </div>

}
