@if (this.tableFilters(); as filters) {
  @if (!filters.columns) {
    <div class="empty-view">
      <div>
        <p>{{ 'overviews.no-columns' | translate }}</p>
        <button class="add-quick-filter robaws-ng-muted-button" (click)="this.onSettingsClicked.emit()">
          <mat-icon fontIcon="settings"/>
          {{ 'overviews.change-columns' | translate }}
        </button>
      </div>
    </div>
  } @else {
    <ng-container *ngIf="tableData$ | async as data">
      @if (fetchCounter() <= 0 && data.loading) {
        <p-table [value]="placeholderRows" [tableStyle]="{ 'min-width': '50rem' }" class="placeholder-table">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-skeleton/>
              </th>
              <th>
                <p-skeleton/>
              </th>
              <th>
                <p-skeleton/>
              </th>
              <th>
                <p-skeleton/>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i>
            <tr>
              <td>
                <p-skeleton/>
              </td>
              <td>
                <p-skeleton/>
              </td>
              <td>
                <p-skeleton/>
              </td>
              <td>
                <p-skeleton/>
              </td>
            </tr>
          </ng-template>
        </p-table>
      }
      @else {

      <p-table [lazy]="true"
               [stateKey]="undefined"
               [metaKeySelection]="true"
               [resizableColumns]="true"
               [lazyLoadOnInit]="false"
               [loading]="data.loading"
               [columns]="filters.columns"
               [value]="data.rows"
               [rows]="this.tablePagination().pageSize"
               [totalRecords]="data.totalItems"
               [multiSortMeta]="filters.sorts"
               [selection]="this.selectedItems$ | async"
               (selectionChange)="this.onSelectionChange($event)"
               (onColResize)="this.onColumnResize()"
               (wheel)="this.onScroll()"
               (onSort)="onSort($event)"
               columnResizeMode="expand"
               selectionMode="multiple"
               sortMode="multiple"
               scrollHeight="100%"
               #table>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="p-frozen-column" style="width: 4rem" pResizableColumn>
              <p-tableHeaderCheckbox/>
            </th>

            @for (column of columns; track column) {
              @if (column.sortable) {
                <th [pSortableColumn]="column.path"
                    [style]="column.width ? column.width + 'px' : ''"
                    pResizableColumn>
                  <div class="p-title">
                    <div class="p-title-content">
                      <div>{{ column.name }}</div>
                    </div>
                    <p-sortIcon class="p-title-sorter" [field]="column.path"></p-sortIcon>
                  </div>
                </th>
              } @else {
                <th pResizableColumn>
                  <div class="p-title">
                    <div class="p-title-content">
                      <div>{{ column.name }}</div>
                    </div>
                  </div>
                </th>
              }
            }
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData"
              [pSelectableRowIndex]="rowIndex"
              (dblclick)="onRowDoubleClick($event, rowData)"
              [attr.style]="(rowData.color ? 'background: ' + rowData.color + '; ' : '') + 'color: ' + rowData.textColor + ';'"

              dynamic-overview-table-row [rowData]="rowData"
              [columns]="this.activeTab()?.view?.columns"
              [rowIndex]="rowIndex"
              [resourceType]="resourceType()"
              [metadataPaths]="metadataPaths()"
              (onSelectClick)="this.checkRangeSelect($event[0], $event[1])"
          ></tr>
        </ng-template>
      </p-table>

      <p-contextMenu *ngIf="table.el && !isTouchDevice()"
                     [target]="table.el.nativeElement"
                     [model]="this.contextMenuItems()">

        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link">
            <mat-icon [fontIcon]="item.icon" [ngStyle]="item.iconStyle"/>
            <span class="ml-2" style="line-height: 24px;">{{ item.label }}</span>
          </a>
        </ng-template>
      </p-contextMenu>
      }

      <dynamic-overview-paginator [totalItems]="data.totalItems"
                                  [pageSize]="this.tablePagination().pageSize"
                                  [page]="this.tablePagination().currentPage"
                                  (pageChange)="this.loadViewPage($event)"
                                  (pageSizeChange)="this.onPageSizeChange($event)"/>
    </ng-container>

  }
}
