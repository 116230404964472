import { ChangeDetectionStrategy, Component, computed, effect, Input, OnInit, Signal, ViewChild, ViewContainerRef } from '@angular/core';

import { InternalServiceMessageService, Path } from '@shared/services';
import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { CommonModule } from '@angular/common';
import { ResourceTypeMetadata } from '@shared/domain';
import { TableModule } from 'primeng/table';
import { View, ViewContentType, ViewFilter } from '@app/robaws/domain';
import { ViewTabsComponent } from '@app/robaws/components/dynamic-overview/view-tabs/view-tabs.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ViewDataRow } from '@app/robaws/domain/ViewDataRow';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { MatIcon } from '@angular/material/icon';
import { RippleModule } from 'primeng/ripple';
import { DynamicOverviewPaginatorComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview-paginator/dynamic-overview-paginator.component';
import { RobawsNgDialogComponent } from '@ui/robaws-ng-dialog/robaws-ng-dialog.component';
import { ViewSettingsDialogComponent } from '@app/robaws/components/dynamic-overview/view-settings-dialog/view-settings-dialog.component';
import { ViewFiltersComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-quick-filters/view-filters.component';
import { bindNativeMethod } from '@app/shared/helpers/injection.helper';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AutoFocus } from 'primeng/autofocus';
import { map } from 'rxjs/operators';
import { ViewSettingsDTO } from '@app/robaws/components/dynamic-overview/view-settings/view-settings.component';
import { DynamicOverviewTableRowComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview-table/dynamic-overview-table-row/dynamic-overview-table-row.component';
import { DynamicOverviewTableComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview-table/dynamic-overview-table.component';
import { DynamicViewStore } from '@app/robaws/components/dynamic-overview/dynamic-view.store';
import { DynamicViewFilterStore } from '@app/robaws/components/dynamic-overview/dynamic-view-filter.store';
import { DynamicViewTableStore } from '@app/robaws/components/dynamic-overview/dynamic-view-table.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';

export type ViewDataRowWithTextColor = ViewDataRow & { textColor: 'black' | 'white' };

export type ViewColumnVO = {
  path: string;
  sortable: boolean;
  primary: boolean;
};

type SearchOptions = {
  searchText: string;
  viewId: string | null;
  currentPage: number;
  pageLimit: number;
  overrideFilters: ViewFilter[];
};

type ViewModel = {
  metadata: ResourceTypeMetadata;
  metadataPaths: Path[];
  activeView: View;
};

@Component({
  selector: 'dynamic-overview',
  templateUrl: 'dynamic-overview.component.html',
  styleUrls: ['dynamic-overview.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ViewTabsComponent,
    SkeletonModule,
    InputTextModule,
    FormsModule,
    NgModelChangeDebouncedDirective,
    MatIcon,
    RippleModule,
    DynamicOverviewPaginatorComponent,
    RobawsNgDialogComponent,
    ViewSettingsDialogComponent,
    ViewFiltersComponent,
    ContextMenuModule,
    AutoFocus,
    TranslateModule,
    DynamicOverviewTableRowComponent,
    DynamicOverviewTableRowComponent,
    DynamicOverviewTableComponent,
  ],
  providers: [DynamicViewStore, DynamicViewFilterStore, DynamicViewTableStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicOverviewComponent extends AbstractWebComponent implements OnInit {
  @Input({ required: true })
  public viewContentType: ViewContentType;

  @Input({ required: true })
  public resourceType: string;

  @Input({ required: true })
  public analytics: string;

  @Input({ required: true })
  public calendar: string;

  @Input({ required: true })
  public financials: string;

  @Input()
  public forceSystemView: string;

  @ViewChild(ViewSettingsDialogComponent)
  private viewSettingsDialog: ViewSettingsDialogComponent;

  fetchCounter = this.dynamicViewTableStore.selectSignal((state) => state.fetchCounter);

  loaded$ = this.dynamicViewStore.select(
    this.dynamicViewStore.select((state) => state.metadataPaths),
    this.dynamicViewStore.select((state) => state.metadata),
    (metadataPaths, metadata) => !!metadataPaths && !!metadata,
  );

  // loaded$ is updated when the view is set
  activeTab = toSignal(this.dynamicViewStore.activeTab$);
  activeTabTypeChanges = toSignal(
    this.dynamicViewStore.activeTab$.pipe(
      map((activeView) => activeView?.view.type),
      distinctUntilChanged(),
    ),
  );

  viewModel: Signal<ViewModel | undefined> = computed(() => {
    const metadata = this.dynamicViewStore.selectSignal((state) => state.metadata)();
    const metadataPaths = this.dynamicViewStore.selectSignal((state) => state.metadataPaths)();
    const activeView = this.dynamicViewStore.selectSignal((state) => state.activeTab)();
    if (!metadata || !metadataPaths || !activeView) {
      return undefined;
    }
    return { metadata, metadataPaths, activeView: activeView.view };
  });

  tableFilters = this.dynamicViewTableStore.selectSignal((state) => state.tableFilters);
  pagination = this.dynamicViewTableStore.selectSignal((state) => state.tablePagination);
  tableData = this.dynamicViewTableStore.selectSignal((state) => state.tableData);

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    private internalServiceMessageService: InternalServiceMessageService,
    private dynamicViewStore: DynamicViewStore,
    private dynamicViewTableStore: DynamicViewTableStore,
    private dynamicViewFilterStore: DynamicViewFilterStore,
  ) {
    super(viewContainerRef);

    bindNativeMethod('getSelectedRowIds', this.getSelectedRowIds.bind(this));
    bindNativeMethod('onAttach', this.onAttach.bind(this));
    bindNativeMethod('getCurrentSearchOptions', this.getCurrentSearchOptions.bind(this));
    bindNativeMethod('reloadView', this.reloadView.bind(this));
    bindNativeMethod('getTotalResults', this.getTotalResults.bind(this));

    effect(() => {
      const activeViewType = this.activeTabTypeChanges();
      if (!activeViewType) {
        return;
      }
      if (activeViewType === 'TABLE') {
        this.showTable();
      } else if (activeViewType === 'ANALYTICS') {
        this.showAnalytics();
      } else if (activeViewType === 'CALENDAR') {
        this.showCalendar();
      } else if (activeViewType === 'FINANCIALS') {
        this.showFinancials();
      }
    });

    effect(() => {
      const activeView = this.activeTab();
      if (activeView && activeView.isNew) {
        this.openSettingsDialog();
      }
    });
  }

  @Input()
  set contextMenuActionsJson(contextMenuActionsJson: string) {
    this.dynamicViewStore.contextMenuActions(contextMenuActionsJson);
  }

  @Input()
  set overrideFiltersJson(overrideFiltersJson: string) {
    this.dynamicViewFilterStore.changeOverrideFilterJson(overrideFiltersJson);
  }

  @Input()
  set _forceSystemView(forceSystemView: string) {
    if (forceSystemView === 'true') {
      this.dynamicViewStore.loadCurrentViews();
    }
  }

  public ngOnInit(): void {
    this.dynamicViewStore.initialize({
      viewContentType: this.viewContentType,
      resourceType: this.resourceType,
    });
  }

  public getSelectedRowIds(): string[] {
    return this.dynamicViewTableStore.selectSignal((state) => state.selectedItemIds)() ?? [];
  }

  public onAttach(): void {
    const currentFetch = this.fetchCounter();
    if (currentFetch === -1) {
      return;
    }
    setTimeout(() => this.dynamicViewTableStore.refreshTable(), 1000);
    this.dynamicViewFilterStore.readSearchTextFromUrl();
  }

  public getCurrentSearchOptions(): SearchOptions | null {
    const tableFilters = this.tableFilters();
    const pagination = this.pagination();
    const activeTab = this.activeTab();
    return {
      searchText: tableFilters.searchText,
      viewId: activeTab?.view.id ?? null,
      currentPage: pagination.currentPage,
      pageLimit: pagination.pageSize,
      overrideFilters: tableFilters.overrideFilters ?? [],
    };
  }

  public reloadView(): void {
    this.dynamicViewTableStore.refreshTableWithLoader();
  }

  public getTotalResults(): number {
    return this.tableData()?.totalItems;
  }

  protected openSettingsDialog(): void {
    this.viewSettingsDialog.openDialog();
  }

  protected onViewSettingsChanged(viewSettings: ViewSettingsDTO): void {
    this.dynamicViewStore.updateViewSettings(viewSettings);
  }

  protected switchToTable(): void {
    this.dynamicViewStore.changeCurrentViewType('TABLE');
  }

  showTable(): void {
    this.hideAll();
  }

  protected switchToAnalytics(): void {
    this.dynamicViewStore.changeCurrentViewType('ANALYTICS');
  }

  protected updateAnalytics(): void {
    if (!this.isAnalyticsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch('update-analytics', {
      viewContentType: this.viewContentType,
    });
  }

  private toggleAnalytics(visible: boolean): void {
    if (!this.isAnalyticsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch(visible ? 'show-analytics' : 'hide-analytics', {
      viewContentType: this.viewContentType,
    });
  }

  showAnalytics(): void {
    this.toggleAnalytics(true);
    this.updateAnalytics();
  }

  protected switchToCalendar(): void {
    this.dynamicViewStore.changeCurrentViewType('CALENDAR');
  }

  protected updateCalendar(): void {
    if (!this.isCalendarEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch('update-calendar', {
      viewContentType: this.viewContentType,
    });
  }

  private toggleCalendar(visible: boolean): void {
    if (!this.isCalendarEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch(visible ? 'show-calendar' : 'hide-calendar', {
      viewContentType: this.viewContentType,
    });
  }

  showCalendar(): void {
    this.toggleCalendar(true);
    this.updateCalendar();
  }

  protected switchToFinancials(): void {
    this.dynamicViewStore.changeCurrentViewType('FINANCIALS');
  }

  protected updateFinancials(): void {
    if (!this.isFinancialsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch('update-financials', {
      viewContentType: this.viewContentType,
    });
  }

  private toggleFinancials(visible: boolean): void {
    if (!this.isFinancialsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch(visible ? 'show-financials' : 'hide-financials', {
      viewContentType: this.viewContentType,
    });
  }

  showFinancials(): void {
    this.toggleFinancials(true);
    this.updateFinancials();
  }

  protected isAnalyticsEnabled(): boolean {
    return this.analytics === 'true';
  }

  protected isCalendarEnabled(): boolean {
    return this.calendar === 'true';
  }

  protected isFinancialsEnabled(): boolean {
    return this.financials === 'true';
  }

  private hideAll(): void {
    this.internalServiceMessageService.dispatch('hide-all', {
      viewContentType: this.viewContentType,
    });
  }

  onViewDeleted(view: View): void {
    this.dynamicViewStore.deleteView(view);
  }
}
