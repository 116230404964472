import { ChangeDetectionStrategy, Component, effect, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { View } from '@app/robaws/domain';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RobawsNgDialogComponent } from '@ui/robaws-ng-dialog/robaws-ng-dialog.component';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { isTouchDevice } from '@app/shared/helpers/device.helper';
import { DynamicViewStore } from '@app/robaws/components/dynamic-overview/dynamic-view.store';

export type Tab = {
  view: View;
  isNew: boolean;
};

@Component({
  selector: 'view-tabs',
  templateUrl: 'view-tabs.component.html',
  styleUrls: ['view-tabs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    OverlayPanelModule,
    MatIcon,
    MatButton,
    PrimaryColorDirective,
    TranslateModule,
    FormsModule,
    RobawsNgDialogComponent,
    InputTextModule,
    TooltipModule,
    CdkDropList,
    CdkDrag,
    MaterialLoaderDirective,
    NgModelChangeDebouncedDirective,
    DeleteIconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewTabsComponent {
  @ViewChild('viewTabsElement')
  protected viewTabsElement: ElementRef;
  @ViewChild('addTabPanel')
  protected addTabPanel: OverlayPanel;

  protected readonly isTouchDevice = isTouchDevice;

  loadedViews = this.dynamicViewStore.selectSignal((state) => state.loadedViews);
  activeTab = this.dynamicViewStore.selectSignal((state) => state.activeTab);
  viewsByMe = this.dynamicViewStore.selectSignal((state) => state.availableViews.byMe);
  viewsByOthers = this.dynamicViewStore.selectSignal((state) => state.availableViews.sharedWithMe);
  loadingViewsByMeAndOthers = this.dynamicViewStore.selectSignal((state) => state.availableViews.loading);
  availableViewsFilter = this.dynamicViewStore.selectSignal((state) => state.availableViews.filter);

  constructor(private dynamicViewStore: DynamicViewStore) {
    effect(() => {
      const activeTab = this.activeTab();
      if (activeTab) {
        if (this.addTabPanel.overlayVisible) {
          this.addTabPanel.hide();
        }
      }
    });
  }

  protected deleteTab(event: Event, tab: View): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.dynamicViewStore.deleteTab(tab);
  }

  protected openAddTabPanel(event: MouseEvent): void {
    if (this.addTabPanel.overlayVisible) {
      this.addTabPanel.hide();
      return;
    }

    this.updateViewsByMeAndOthers();
    this.addTabPanel.show(event);
  }

  protected openSharedTab(view: View): void {
    this.dynamicViewStore.openAvailableView(view);
  }

  protected setCurrentView(view: View): void {
    if (!view) {
      return;
    }
    this.dynamicViewStore.openView(view);
    this.addTabPanel.hide();
  }

  protected handleMouseUpEvent(event: MouseEvent, tab: View): void {
    // middle mouse button clicked
    if (!(tab.parentView?.systemView ?? false) && event.button === 1) {
      this.deleteTab(event, tab);
    }
  }

  protected onTabDrop(event: CdkDragDrop<any, any>): void {
    let newIndex = event.currentIndex;

    // not allowing the view to be dropped in front of the system view
    if (newIndex === 0) {
      newIndex = 1;
    }

    this.dynamicViewStore.patchTabOrder({ oldIndex: event.previousIndex, newIndex: newIndex });
  }

  /**
   * Changes the behavior of scrolling to scroll horizontally instead of vertically.
   */
  protected handleWheelEvent(event: WheelEvent): void {
    if (event.deltaX < 0 || event.deltaX > 0) {
      return;
    }
    event.preventDefault();
    if (event.deltaMode === 0) {
      this.viewTabsElement.nativeElement.scrollLeft += event.deltaY; // can ignore the soft warning, this is intended
    }
  }

  protected updateViewsByMeAndOthers(filter: string = '') {
    this.dynamicViewStore.loadViewsByMeAndOthers(filter);
  }

  protected createNewView(): void {
    this.dynamicViewStore.createNewView();
  }

  protected deleteView(view: View, event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (view.systemView || view.parentView?.systemView) {
      return;
    }
    this.dynamicViewStore.deleteView(view);
  }

  protected toggleVisibility(view: View, event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    view.visibility = view.visibility === 'PRIVATE' ? 'EVERYONE' : 'PRIVATE';

    this.dynamicViewStore.patchAvailableViewVisibility(view);
  }
}
