<robaws-ng-select [items]="this.entities() ?? []"
                  [itemSize]="100"
                  [totalItems]="this.totalItems()"
                  [showPlaceholder]="!!this.label() && this.label() !== ''"
                  [placeholder]="this.label() ?? ''"
                  [multiple]="this.multiple()"
                  [lazy]="true"
                  [loading]="this.loading()"
                  [showFilter]="true"
                  [inputClearIcon]="this.clearIcon()"
                  [value]="this.currentValue()"
                  (valueChange)="this.onValueChange($event)"
                  (onLazyLoad)="this.onLazyLoad($event)"
                  (onFilter)="this.onFilter($event)"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="id">

  @if (this.inputTemplate(); as inputTemplate) {
    <ng-template robawsNgTemplate="input" let-onClick>
      <ng-container [ngTemplateOutlet]="inputTemplate"
                    [ngTemplateOutletContext]="{ $implicit: onClick }"/>
    </ng-template>
  }

</robaws-ng-select>
