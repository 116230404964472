import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChildren,
  input,
  model,
  QueryList,
  signal,
  TemplateRef,
} from '@angular/core';
import { finalize, map, Observable, switchMap, tap } from 'rxjs';

import { DynamicResourceItem, MetadataProviderType, PageDTO, ResourceTypeMetadata } from '@shared/domain';
import { DynamicResourceTypeEntityProvider } from '@app/shared/services/dynamic-resource-type-entity-provider';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgTemplateOutlet, SlicePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { RobawsNgSelectComponent, RobawsNgSelectFilterEvent, RobawsNgSelectLazyLoadEvent } from '@ui/robaw-ng-select/robaws-ng-select.component';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';
import { ResourceTypeEntityParams } from '@shared/services';
import { computedAsync, debouncedSignal } from '@app/shared/helpers/signal.helper';

export type ResourceEntityProvider = (resourceType: string, params: ResourceTypeEntityParams) => Observable<PageDTO<DynamicResourceItem>>;

@Component({
  selector: 'dynamic-resource-combo',
  templateUrl: 'dynamic-resource-combo.component.html',
  standalone: true,
  imports: [NgSelectModule, FormsModule, MultiSelectModule, SlicePipe, MatIcon, RobawsNgSelectComponent, RobawsNgTemplateDirective, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicResourceComboComponent implements AfterContentInit {
  public metadataProviderType = input.required<MetadataProviderType>();
  public metadata = input.required<ResourceTypeMetadata>();
  public label = input<string | undefined | null>(undefined);
  public value = model<string | undefined | null>(undefined);
  public multiple = input<boolean>(false);
  public clearIcon = input<boolean>(true);
  public resourceEntityProvider = input<ResourceEntityProvider | undefined | null>(undefined);
  protected currentValue = computed<string | string[] | undefined | null>(() => {
    const value = this.value();

    if (this.multiple()) {
      if (value) {
        try {
          return JSON.parse(value);
        } catch (e) {
          return value.split(',');
        }
      }

      return [];
    } else {
      return value;
    }
  });
  protected searchText = signal<string>('');
  protected currentPage = signal<number>(0);
  protected loading = signal<boolean>(false);
  protected totalItems = signal<number>(0);
  protected inputTemplate = signal<TemplateRef<any> | undefined>(undefined);
  private debouncedSearchText = debouncedSignal<string>(this.searchText, 300);
  protected entities = computedAsync<DynamicResourceItem[]>(() => {
    const searchText = this.debouncedSearchText();
    const page = this.currentPage();

    this.loading.set(true);
    return this.searchResourceTypeEntities(searchText, page).pipe(finalize(() => this.loading.set(false)));
  });
  private dynamicResourceTypeEntityProvider = computed<DynamicResourceTypeEntityProvider>(
    () => new DynamicResourceTypeEntityProvider(this.metadataProviderType()),
  );
  private maxPages: number = -1;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate.set(template.template);
        }
      });
    }
  }

  public onValueChange(value: any): void {
    if (value && this.multiple() && Array.isArray(value)) {
      if (value.length === 0) {
        value = '';
      } else {
        value = JSON.stringify(value);
      }
    }

    this.value.set(value);
  }

  public clear(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.searchText.set('');
    this.onValueChange(undefined);
  }

  public searchEntitiesByIds(ids: string[]): Observable<DynamicResourceItem[]> {
    const resourceEntityProvider = this.resourceEntityProvider();

    if (resourceEntityProvider) {
      return resourceEntityProvider(this.metadata().name, {
        filter: '',
        page: 0,
        id: ids,
      }).pipe(map((page) => page.items));
    }

    return this.dynamicResourceTypeEntityProvider()
      .searchEntities(this.metadata().name, {
        filter: '',
        page: 0,
        id: ids,
      })
      .pipe(map((page) => page.items));
  }

  protected onLazyLoad(event: RobawsNgSelectLazyLoadEvent): void {
    const page = Math.ceil((event.first + 1) / 100);

    if (this.currentPage() === page) {
      return;
    }
    // no max pages known yet or max pages is greater than the current page
    if (this.maxPages === -1 || this.maxPages > this.currentPage() + 1) {
      this.currentPage.set(this.currentPage() + 1);
    }
  }

  protected onFilter(event: RobawsNgSelectFilterEvent) {
    this.searchText.set(event.filter);
  }

  private searchResourceTypeEntities(searchText: string = '', page: number = 0): Observable<DynamicResourceItem[]> {
    const currentValue = this.currentValue();

    // checking length because empty array would always return all entities
    if (!currentValue || this.currentValue.length === 0) {
      return this.searchEntities(searchText, page);
    }

    const idsAsArray = this.multiple() ? (currentValue as string[]) : [currentValue as string];

    return this.searchEntitiesByIds(idsAsArray).pipe(
      switchMap((entitiesById) => {
        return this.searchEntities(searchText, page).pipe(
          map((entities) => {
            return [...entitiesById, ...entities.filter((it) => !entitiesById.find((entity) => entity.id === it.id))];
          }),
        );
      }),
    );
  }

  private searchEntities(searchText: string = '', page: number = 0): Observable<DynamicResourceItem[]> {
    const resourceEntityProvider = this.resourceEntityProvider();

    if (resourceEntityProvider) {
      return resourceEntityProvider(this.metadata().name, {
        filter: searchText,
        page,
        pageSize: 100,
        sort: '',
      }).pipe(
        tap((page) => {
          this.maxPages = page.totalPages;
          this.totalItems.set(page.totalItems);
        }),
        map((page) => page.items),
      );
    }

    return this.dynamicResourceTypeEntityProvider()
      .searchEntities(this.metadata().name, {
        filter: searchText,
        page,
        pageSize: 100,
        sort: '',
      })
      .pipe(
        tap((page) => {
          this.maxPages = page.totalPages;
          this.totalItems.set(page.totalItems);
        }),
        map((page) => page.items),
      );
  }
}
