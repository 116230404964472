import { ChangeDetectionStrategy, Component, effect, ElementRef, output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Table, TableModule } from 'primeng/table';
import { isTouchDevice } from '@app/shared/helpers/device.helper';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DynamicOverviewPaginatorComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview-paginator/dynamic-overview-paginator.component';
import { DynamicOverviewTableRowComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview-table/dynamic-overview-table-row/dynamic-overview-table-row.component';
import { MatIcon } from '@angular/material/icon';
import { Ripple } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { ViewDataRowWithTextColor } from '@app/robaws/components/dynamic-overview/dynamic-overview.component';
import { UrlService } from '@shared/services';
import { toSignal } from '@angular/core/rxjs-interop';
import { DynamicViewStore } from '@app/robaws/components/dynamic-overview/dynamic-view.store';
import { DynamicViewTableStore } from '@app/robaws/components/dynamic-overview/dynamic-view-table.store';
import { DomHandler } from 'primeng/dom';
import { combineLatestWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortEvent } from 'primeng/api';

export type PatchedTable = Table & {
  restoreTableWidth: () => void;
};

function patchPrimengTable(table: Table) {
  // @ts-ignore
  table['restoreTableWidth'] = function () {
    if (this.columnResizeMode === 'expand' && this.tableWidthState) {
      this.setResizeTableWidth(this.tableWidthState + 'px');
    }
  };

  const createStyleElement = table.createStyleElement;

  // @ts-ignore
  table['createStyleElement'] = function () {
    // Primeng wants to leak, but i dont want this :)
    this.destroyStyleElement();
    createStyleElement.call(this);
  };

  return table as PatchedTable;
}

@Component({
  selector: 'dynamic-overview-table',
  templateUrl: 'dynamic-overview-table.component.html',
  styleUrls: ['dynamic-overview-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ContextMenuModule,
    DynamicOverviewPaginatorComponent,
    DynamicOverviewTableRowComponent,
    MatIcon,
    Ripple,
    SkeletonModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicOverviewTableComponent {
  viewContentType = this.dynamicViewStore.selectSignal((state) => state.viewContentType);
  resourceType = this.dynamicViewStore.selectSignal((state) => state.resourceType);
  contextMenuItems = this.dynamicViewStore.selectSignal((state) => state.contextMenuActions);
  activeTab = this.dynamicViewStore.selectSignal((state) => state.activeTab);
  metadataPaths = this.dynamicViewStore.selectSignal((state) => state.metadataPaths);

  protected selectedItems: ViewDataRowWithTextColor[] = [];

  onSettingsClicked = output();

  protected readonly isTouchDevice = isTouchDevice;
  placeholderRows = Array(30)
    .fill(0)
    .map((_, i) => i);
  currentScrollTop = 0;
  @ViewChild(Table)
  set _table(table: Table) {
    this.table = patchPrimengTable(table);
  }

  table?: PatchedTable;

  tableFilters = toSignal(this.dynamicViewTableStore.select((state) => state.tableFilters));
  tablePagination = this.dynamicViewTableStore.selectSignal((state) => state.tablePagination);
  tableData$ = this.dynamicViewTableStore.select((state) => state.tableData);
  fetchCounter = this.dynamicViewTableStore.selectSignal((state) => state.fetchCounter);

  tableLayout = this.dynamicViewTableStore.selectSignal((state) => state.tableLayout);
  selectedItems$ = this.tableData$.pipe(
    combineLatestWith(this.dynamicViewTableStore.select((state) => state.selectedItemIds)),
    map(([tableData, selectedItemIds]) => {
      if (!selectedItemIds) {
        return [];
      }
      return tableData.rows.filter((row) => selectedItemIds.includes(row.id));
    }),
  );

  constructor(
    private urlService: UrlService,
    private dynamicViewStore: DynamicViewStore,
    private dynamicViewTableStore: DynamicViewTableStore,
    element: ElementRef,
  ) {
    // Sync table layout with the store
    effect(() => {
      const tableLayout = this.tableLayout();
      if (!tableLayout || !this.table) {
        return;
      }
      this.table.columnWidthsState = tableLayout.columnWidths;
      if (tableLayout.tableWidth) {
        this.table.tableWidthState = String(tableLayout.tableWidth);
      } else {
        this.table.tableWidthState = DomHandler.getOuterWidth(element?.nativeElement);
      }
      // Primeng table does not like changing columns on the fly.
      // Our work around is to set the columnWidth to undefined so it would recomputed the column widths.
      // The problem is that the table might have made an old style. => Remove that
      // We also need to restore the table width to maximum width if the width is unknown and call our custom restoreTableWidth method
      this.table.destroyStyleElement();
      this.table.restoreColumnWidths();
      this.table.restoreTableWidth();
    });
  }

  protected onRowDoubleClick(event: MouseEvent, rowData: ViewDataRowWithTextColor): void {
    event.preventDefault();
    this.urlService.navigateToResourceType(this.resourceType(), rowData.id);
  }

  // fix for shift selecting rows while clicking on the checkbox (see: https://github.com/primefaces/primeng/issues/5496)
  protected checkRangeSelect(event: MouseEvent, index: number) {
    if (this.table) {
      if (event.button === 0 && event.shiftKey) {
        this.table.selectRange(event, index);
        this.table.anchorRowIndex = null;
      } else {
        this.table.anchorRowIndex = index;
      }
    }
  }

  protected onScroll(): void {
    if (this.table) {
      this.currentScrollTop = this.table.el.nativeElement.querySelector('.p-datatable-wrapper').scrollTop;
    }
  }

  onPageSizeChange($event: number) {
    this.dynamicViewTableStore.updatePagination({ pageSize: $event });
  }

  loadViewPage($event: number) {
    this.dynamicViewTableStore.updatePagination({ currentPage: $event });
  }

  protected onColumnResize(): void {
    if (!this.table) {
      return;
    }

    const state: any = {};
    this.table.saveColumnWidths(state);

    this.dynamicViewTableStore.changeTableLayout({
      tableWidth: state.tableWidth,
      columnWidths: state.columnWidths,
    });
  }

  onSelectionChange($event: ViewDataRowWithTextColor[]) {
    this.dynamicViewTableStore.updateSelectedItemIds($event.map((item) => item.id));
  }

  onSort(event: SortEvent) {
    // @ts-ignore - The event does not have it capitalized
    this.dynamicViewTableStore.updateSorts(event.multisortmeta ?? []);
  }
}
