@if (this.loaded$ | async) {
  <div class="view-controls">
    <view-tabs/>
  </div>

  @if (this.viewModel(); as vm) {
    <div class="view-header">
      <view-filters/>

      <div class="view-header-buttons">
        <div class="view-display-buttons" *ngIf="this.isAnalyticsEnabled() || this.isCalendarEnabled() || this.isFinancialsEnabled()">
          <mat-icon (click)="this.switchToTable()"
                    fontIcon="list"
                    class="view-type-icon table-icon"
                    tooltipPosition="top"
                    [pTooltip]="'table' | translate"
                    [attr.data-active]="vm.activeView.type === 'TABLE'"/>

          <mat-icon *ngIf="this.isFinancialsEnabled()"
                    (click)="this.switchToFinancials()"
                    fontIcon="euro_symbol"
                    class="view-type-icon table-icon"
                    tooltipPosition="top"
                    [pTooltip]="'financials' | translate"
                    [attr.data-active]="vm.activeView.type === 'FINANCIALS'"/>

          <mat-icon *ngIf="this.isAnalyticsEnabled()"
                    (click)="this.switchToAnalytics()"
                    fontIcon="bar_chart"
                    class="view-type-icon table-icon"
                    tooltipPosition="top"
                    [pTooltip]="'analytics' | translate"
                    [attr.data-active]="vm.activeView.type === 'ANALYTICS'"/>

          <mat-icon *ngIf="this.isCalendarEnabled()"
                    (click)="this.switchToCalendar()"
                    fontIcon="date_range"
                    class="view-type-icon table-icon"
                    tooltipPosition="top"
                    [pTooltip]="'calendar' | translate"
                    [attr.data-active]="vm.activeView.type === 'CALENDAR'"/>
        </div>

        <mat-icon (click)="this.openSettingsDialog()"
                  fontIcon="settings"
                  class="settings-icon"/>
      </div>
    </div>

    <dynamic-overview-table [hidden]="vm.activeView.type !== 'TABLE'"></dynamic-overview-table>

    <view-settings-dialog [view]="vm.activeView"
                          [paths]="vm.metadataPaths"
                          [metadata]="vm.metadata"
                          (settingsChanged)="this.onViewSettingsChanged($event)"
                          (viewDeleted)="this.onViewDeleted($event)"/>
  }
}
