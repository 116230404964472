import { ChangeDetectionStrategy, Component, EventEmitter, Output, Signal, ViewChild } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ViewFilter, ViewFilterGroup } from '@app/robaws/domain';
import { MatIcon } from '@angular/material/icon';
import { FilterBuilderComponent } from '@app/robaws/components/standalone-filter-builder/filter-builder/filter-builder.component';
import { LowerCasePipe, NgIf } from '@angular/common';
import { ViewFilterGroupEditorComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-filter-group-editor/view-filter-group-editor.component';
import { ViewQuickFilterComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-quick-filters/view-quick-filter/view-quick-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { ViewSettingsDialogComponent } from '@app/robaws/components/dynamic-overview/view-settings-dialog/view-settings-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { ViewFilterEditorComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-filter-editor/view-filter-editor.component';
import { DynamicViewStore } from '@app/robaws/components/dynamic-overview/dynamic-view.store';
import { DynamicViewFilterStore } from '@app/robaws/components/dynamic-overview/dynamic-view-filter.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { ResourceTypeMetadata } from '@shared/domain';

export type ViewFilters = {
  filterGroup: ViewFilterGroup;
  additionalFilters: ViewFilter[];
  overrideFilters: ViewFilter[];
};

@Component({
  selector: 'view-filters',
  templateUrl: 'view-filters.component.html',
  styleUrls: ['view-filters.component.scss'],
  standalone: true,
  imports: [
    SidebarModule,
    MatIcon,
    FilterBuilderComponent,
    NgIf,
    ViewFilterGroupEditorComponent,
    ViewQuickFilterComponent,
    FormsModule,
    NgModelChangeDebouncedDirective,
    ViewSettingsDialogComponent,
    TranslateModule,
    CheckboxModule,
    LowerCasePipe,
    ReactiveFormsModule,
    DeleteIconComponent,
    ViewFilterEditorComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewFiltersComponent {
  @Output()
  public filtersChanged = new EventEmitter<ViewFilters>();
  @Output()
  public overrideFiltersCleared = new EventEmitter<void>();

  protected editingAdvancedFilters: boolean = false;
  @ViewChild(ViewFilterGroupEditorComponent)
  private viewFilterGroupEditor?: ViewFilterGroupEditorComponent;

  viewContentType = this.dynamicViewStore.selectSignal((state) => state.viewContentType);
  metadata = this.dynamicViewStore.selectSignal((state) => state.metadata) as Signal<ResourceTypeMetadata>;

  quickFilterPaths = this.dynamicViewFilterStore.selectSignal((state) => state.quickFilterPaths);
  searchQuery = this.dynamicViewFilterStore.selectSignal((filterState) => filterState.searchText);
  viewFilterGroup = this.dynamicViewFilterStore.selectSignal((filterState) => filterState.viewFilterGroup);
  overrideFilters = this.dynamicViewFilterStore.selectSignal((filterState) => filterState.overrideFilters);
  additionalFilters = this.dynamicViewFilterStore.selectSignal((filterState) => {
    const originalFilters = filterState.additionalFilters;
    // Return a copy. Subcomponent mutates the objects within the array.
    return originalFilters.map((filter) => ({ ...filter }));
  });

  amountOfFilters = toSignal(this.dynamicViewFilterStore.selectTotalFilterCount$, { initialValue: 0 });
  hasDeletedFilter = toSignal(this.dynamicViewFilterStore.selectHasDeletedFilter$);
  hasArchivedFilter = toSignal(this.dynamicViewFilterStore.selectHasArchivedFilter$);

  constructor(
    private dynamicViewStore: DynamicViewStore,
    private dynamicViewFilterStore: DynamicViewFilterStore,
  ) {}

  protected editAdvancedFilters(): void {
    this.editingAdvancedFilters = true;
  }

  protected save(): void {
    this.dynamicViewFilterStore.saveAllFilters();
  }

  protected onSearchQueryUpdate(text: string): void {
    this.dynamicViewFilterStore.changeSearchText(text);
  }

  protected clearSearch(): void {
    this.onSearchQueryUpdate('');
  }

  protected toggleArchived(archived: boolean): void {
    this.dynamicViewFilterStore.updateToggleArchivedFilter(archived);
  }

  protected toggleDeleted(deleted: boolean): void {
    this.dynamicViewFilterStore.updateToggleDeletedFilter(deleted);
  }

  protected onAdditionalFiltersChanged(additionalFilters: ViewFilter[]): void {
    this.dynamicViewFilterStore.changeAdditionalFilters(additionalFilters);
  }

  protected deleteOverrideFilter(overrideFilter: ViewFilter): void {
    this.dynamicViewFilterStore.updateDeleteOverrideFilter(overrideFilter);
  }

  protected onClearAllClicked(): void {
    this.dynamicViewFilterStore.updateClearAll();
  }

  protected clearAll(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.viewFilterGroupEditor) {
      this.viewFilterGroupEditor.clearAll();
      this.save();
    }
  }
}
