import { ChangeDetectionStrategy, Component, computed, input, model } from '@angular/core';
import { DynamicResourceItem, PageDTO, ResourceTypeMetadata } from '@shared/domain';
import { Path, PathService, ResourceTypeEntityParams } from '@shared/services';
import { DynamicTypeInputComponent, InputData } from '@shared/components/inputs/dynamic-type-input';
import { TranslateModule } from '@ngx-translate/core';
import { RobawsOperator, ViewContentType, ViewFilter } from '@app/robaws/domain';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { detectInputTypeFromPath, InputType } from '@shared/helpers';
import { NgIf } from '@angular/common';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { ViewFilterValueService } from '@app/robaws/services/view-filter-value-service.service';
import { computedAsync } from '@app/shared/helpers/signal.helper';
import { filter } from 'rxjs/operators';

type ViewState = {
  currentPath: Path;
  currentResourceTypeMetadata: ResourceTypeMetadata;
  currentInputData: InputData;
  currentInputType: InputType;
  operatorOverride?: RobawsOperator;
};

@Component({
  selector: 'view-quick-filter',
  templateUrl: 'view-quick-filter.component.html',
  styleUrls: ['view-quick-filter.component.scss'],
  standalone: true,
  imports: [DynamicTypeInputComponent, TranslateModule, NgIf, RobawsNgTemplateDirective, MatIcon, TooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewQuickFilterComponent {
  public viewContentType = input.required<ViewContentType>();
  public metadata = input.required<ResourceTypeMetadata>();
  public path = input.required<string>();
  public additionalFilters = model.required<ViewFilter[]>();
  protected currentValue = computed<string | undefined>(() => {
    const path = this.path();
    const additionalFilters = this.additionalFilters();

    if (!path || !additionalFilters) {
      return undefined;
    }

    const filter = additionalFilters.find((filter) => filter.path === path);

    if (filter) {
      return filter.value;
    } else {
      return undefined;
    }
  });
  protected currentValueCount = computed<number>(() => {
    const viewState = this.currentViewState();
    if (!viewState) {
      return 0;
    }
    // if the current input type is not a multiselect type, we don't need to show the count -> avoiding unnecessary computation
    if (viewState.currentInputType !== 'MULTISELECT_POSSIBLE_VALUES' && viewState.currentInputType !== 'MULTISELECT_COMBOBOX') {
      return 0;
    }
    const currentValue = this.currentValue();

    if (!currentValue || currentValue === '') {
      return 0;
    }

    try {
      return JSON.parse(currentValue).length;
    } catch (e) {
      return currentValue.split(',').length;
    }
  });
  private readonly dynamicResourceTypeProvider = new DynamicResourceTypeProvider('VIEW');
  protected currentViewState = computedAsync<ViewState | undefined>(() => {
    const metadata = this.metadata();
    const path = this.path();

    if (!metadata || !path) {
      return undefined;
    }

    return this.pathService.getPath(this.dynamicResourceTypeProvider, metadata.name, path, true).pipe(
      filter((path): path is Path => !!path),
      switchMap((path) =>
        combineLatest([of(path), path.targetResourceType ? this.dynamicResourceTypeProvider.getMetadata(path.targetResourceType) : of(undefined)]),
      ),
      map(([path, targetMetadata]): ViewState => {
        const currentResourceTypeMetadata = path.parentMetadata;
        let currentInputType: InputType = detectInputTypeFromPath(path);
        let operatorOverride: RobawsOperator | undefined = undefined;

        if (currentInputType === 'COMBOBOX') {
          currentInputType = 'MULTISELECT_COMBOBOX';
          operatorOverride = 'IS_ONE_OF';
        } else if (currentInputType === 'POSSIBLE_VALUES') {
          currentInputType = 'MULTISELECT_POSSIBLE_VALUES';
          operatorOverride = 'IS_ONE_OF';
        } else if (currentInputType === 'STRING' && path.dataType === 'TEXT') {
          operatorOverride = 'CONTAINS';
        } else if (path.dataType === 'DATE' || path.dataType === 'DATE_TIME') {
          operatorOverride = 'IS_BETWEEN';
          currentInputType = 'DATE_RANGE';
        } else if (path.dataType === 'NUMBER' || path.dataType === 'DECIMAL') {
          operatorOverride = 'IS_BETWEEN';
          currentInputType = 'NUMBER_RANGE';
        }

        let currentInputData: InputData;
        if (path.targetResourceType) {
          currentInputData = {
            dataType: path.dataType,
            expectedInputTypeMetadata: targetMetadata,
            possibleValues: path.possibleValues,
          };
        } else {
          currentInputData = {
            dataType: path.dataType,
            possibleValues: path.possibleValues,
          };
        }

        return {
          currentPath: path,
          currentResourceTypeMetadata,
          currentInputData,
          currentInputType,
          operatorOverride,
        } as ViewState;
      }),
    );
  });

  constructor(
    private pathService: PathService,
    private viewFilterValueService: ViewFilterValueService,
  ) {}

  protected readonly resourceEntityProvider = (resourceType: string, params: ResourceTypeEntityParams): Observable<PageDTO<DynamicResourceItem>> => {
    return this.viewFilterValueService.getFilterValues(
      this.viewContentType(),
      resourceType,
      params.filter,
      params.page,
      params.pageSize ?? 100,
      params.id,
    );
  };

  protected onValueChange(event: string | undefined): void {
    const currentViewState = this.currentViewState();
    const path = this.path();
    let additionalFilters = this.additionalFilters();

    if (!currentViewState || !additionalFilters || !path) {
      return;
    }

    const operator = currentViewState.operatorOverride ?? 'EQUALS';

    if (!event || event === '') {
      additionalFilters = additionalFilters.filter((filter) => filter.path !== path);
    } else {
      const filter = additionalFilters.find((filter) => filter.path === path);
      const newFilter = {
        path: path,
        operator: operator,
        value: event,
      };

      if (filter) {
        additionalFilters = additionalFilters.map((f) => {
          if (f.path === path) {
            return newFilter;
          }

          return f;
        });
      } else {
        additionalFilters = [...additionalFilters, newFilter];
      }
    }

    // updating additional filters which will in turn also update the currentValue computed property
    this.additionalFilters.set(additionalFilters);
  }

  protected clear(): void {
    this.onValueChange('');
  }
}
