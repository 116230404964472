import { debounceTime, Observable, Subscription } from 'rxjs';
import { effect, signal, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

/**
 * A signal that is computed asynchronously.
 *
 * @param computation A function that returns an observable. The signal will be updated with the value of the observable.
 */
export function computedAsync<T>(computation: () => Observable<T> | undefined): Signal<T | undefined> & { recompute: () => void } {
  const writeableSignal = signal<T | undefined>(undefined);

  // Save current subscription to be able to unsubscribe
  let subscription: Subscription;

  const recompute = () => {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }

    const observable = computation();

    if (observable) {
      subscription = observable.subscribe((result) => writeableSignal.set(result));
    } else {
      writeableSignal.set(undefined);
    }
  };

  effect(recompute, { allowSignalWrites: true });

  // Add the recompute function to the returned signal, so that it can be called from the outside
  return Object.assign(writeableSignal, { recompute });
}

/**
 * A helper function to create a debounced signal from a signal.
 *
 * @param signal The signal to debounce
 * @param debounce The debounce time in milliseconds
 */
export function debouncedSignal<T>(signal: Signal<T>, debounce: number = 0): Signal<T> {
  return toSignal(toObservable(signal).pipe(debounceTime(debounce)), { initialValue: signal() });
}
